import React, { useState, useEffect } from "react";
import {
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@material-ui/icons";
import styled from "styled-components";
import { colors } from "../../res/values/values";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as manufacturerActions from "../../redux/actions/manufacturerActions";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";
import { useNavigate } from "react-router-dom";
import BrandsCarouselGlide from "./BrandCarouselGlide";

//#region Styles
const BodyDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1440px;
  margin: 40px 10px;
`;
const Glide = styled.div`
  float: left;
  width: 33%;
`;
const Poster = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: scale-down;
  opacity: 0.8;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

//#endregion
function BrandList(props) {
  //const [test,setTest] = useState([]);
  //const [loading,setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const getBrands = async () => {
      if (props.manufacturers.length === 0) await props.actions.getBrands();
      /*
      else {
        let brandList = [];
        await Promise.all(
          props.manufacturers.map(async (brand) => {
            const imagePath = `http://localhost:3000/catalog/Brands/${brand.code}.jpg`;
            const image = new Image();
            image.src = imagePath;
            console.log(image.complete , ' - ',image.naturalHeight);
            if (image.naturalHeight !== 0) {
              brandList.push(brand);
            }
            
          })
        );
        setTest([...brandList]);
        loading && setLoading(false)
      }
      */
      
      
    };
    getBrands();
  }, [ props.manufacturers]);
  const rootBrands = (brand) => {
    navigate(`/catalogsearch/result/?${brand ? `brand=${brand}` : ""}`)
  };
  const replaceImage = (error) => {
    //replacement of broken Image
    error.target.onerror = null
    error.target.src = null;
  };
  return (
    <BodyDiv>
      {props.manufacturers.length > 0 &&
        props.manufacturers.map((brand, index) => {
            const imagePath = `./catalog/Brands/${brand.code}.jpg`;
            const image = new Image();
            image.src = imagePath;
            
            if ( image.naturalHeight !== 0) {
              return (
                <Glide key={index}>
                  <Poster
                    key={brand.code}
                    className="poster"
                    src={`./catalog/Brands/${brand.code}.jpg`}
                    onClick={() => rootBrands(brand.code)}
                  />
                </Glide>
              );
            }
            //if(loading && props.manufacturers.length === index+1) setLoading(false);
        })}
    </BodyDiv>
  );
}
function mapStateToProps(state) {
  return {
    manufacturers: state.manufacturerReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getBrands: bindActionCreators(
        manufacturerActions.getManufacturerList,
        dispatch
      ),
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandList);
