import React, { useEffect } from "react";
import styled from "styled-components";
import ContactContent from "../components/PagesComponents/ContactContent";
import { API_URL } from "../res/values/values";
import { Helmet } from "react-helmet";
const Container = styled.div``;
const Contact = () => {
  useEffect(()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  },[])
  const sendMail = async (values) => {
    let url = `${process.env.REACT_APP_API}/mail`;
    let rv = false;
    await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        mail: values.email,
        name: values.name,
        message: values.message,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        rv = true;
      })
      .catch((err) => {
        rv = false;
      });
    return rv;
  };
  return (
    <Container>
      <Helmet>
        <title>Contact | Star Catering UK Supplies</title>
        <meta name="description" content="Contact | Star Catering UK Supplies" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={document.URL}/>
      </Helmet>
      <ContactContent sendMail={sendMail} />
    </Container>
  );
};

export default Contact;
