import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {colors} from "../../res/values/values"
//#region Styles
const AboutMenuDiv = styled.div`
  align-items: center;
  overflow: hidden;  
  
`;
const AboutButton = styled.div`
  list-style:none ;
  cursor: pointer;
`;

//#endregion
const AboutMenuContent = (props) => {
  const navigate = useNavigate();
  //const handleClick = (myLink) => {
    //window.location.href=myLink;
  //}
  const handleClick = (myLink) => {
    window.open(myLink, '_blank');
  };
  return (
    <AboutMenuDiv>
      <AboutButton onClick={(_) => navigate("/about-us")}>
        <a>About Us</a>  
      </AboutButton>
      <AboutButton onClick={(_) => navigate("/contact")}><a>Get In Touch</a></AboutButton>
      <AboutButton onClick={(_) => navigate("/export-services")}><a>Export Services</a></AboutButton>
      <AboutButton onClick={(_) => navigate("/star-bulletin")}><a>News</a></AboutButton>
      <AboutButton  onClick={(_) => handleClick(`${process.env.PUBLIC_URL}/catalog/ProductCatalog/Product_Guide_Star_Catering_2022.pdf`)}><a >Catalog</a></AboutButton>
    </AboutMenuDiv>
  );
};

export default AboutMenuContent;
