import styled from "styled-components";
import {colors} from "../res/values/values"
import { useNavigate } from "react-router-dom";
const Container = styled.div`
  flex-direction: row;
  display: flex;
  min-height: 40rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 600px) {
    flex-direction: column ;
  }
`;
const Label = styled.h1`
  font-size: 8rem;
  color: #ef4444;
  font-weight: 600;
  margin: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

`;
const TextContainer = styled.div`
  flex-direction: column;
  display: flex;
  @media only screen and (max-width: 600px) {
    justify-content: center;
    align-items:center;
    text-align:center;
  }
`;
const TextLabel = styled.span`
  font-size: 1.5rem;
  color:#4b5563;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 2rem;

`;
const Text = styled.span`
  font-size: 1rem;
  color:#4b5563;
  font-weight: 400;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5rem;
  & a {
    color: #2563ed;
    text-decoration-line: none;
    outline: medium none !important;
    margin-left: .5rem;
    font-weight: 500;
    
  }

`;
function NoPage() {
  const navigate = useNavigate()
  return (
    <Container>
      <Label>404</Label>
      <TextContainer>
        <TextLabel> Oops! Page not found.</TextLabel>
        <Text>  The page you ara looking for was not found</Text>
        <Text>  
          You may return to 
          <a href={window.location.origin} class="text-blue-600 hover:underline dark:text-blue-500">home page</a> or try
          using the search form.
        </Text>
      </TextContainer>
      
    </Container>
  );
}

export default NoPage;
