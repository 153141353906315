import styled from "styled-components";

//#region Styles

const Container = styled.div``;
const InformationDiv = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "myriad-pro", Arial, sans-serif;
  & p {
    color: #000;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400;
    margin: 0;
    padding-bottom: 10px;
    padding-top: 15px;
  }
  & span {
    color: #000;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400;
  }
  & h1 {
    color: #262626;
    font-size: 2em;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 1rem;
  }
  & a {
    color: #334862;
  }

  & h2 {
    margin-bottom: 0.5em;
    color: #231f20;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    margin-left: 1rem;
  }
  & h4 {
    color: #ffffff;
    font-size: 15px;
    line-height: 1.58em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const DivContent = styled.div`
  width: 100%;
  margin: 1rem;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
const Image = styled.img`
  width: 80%;
  margin-left: 1rem;
  object-fit: contain;
`;
const ListDiv = styled.div`
  width: 100%;
  margin-left: 1rem;
  margin-bottom: 1rem;
  & ul {
    float: left;
    list-style: none;
    width: 20%;
    text-align: center;
    @media only screen and (max-width: 899px) {
      width: 40%;
    }
    @media only screen and (max-width: 599px) {
      width: 80%;
    }
  }
  & li {
    color: #231f20;
    font-family: "myriad-pro", Arial, sans-serif;
    font-size: 14px;
    line-height: 24px;
    margin-left: 0 !important;
    & img {
      max-width: 100%;
    }
  }
`;
//#endregion
function AboutContent() {
  return (
    <Container>
      <InformationDiv>
        <DivContent>
          <h1>About Us</h1>
          <h3>
            Star Catering Supplies Ltd offers the highest quality of
            Products/Services on the market today.
          </h3>
          <p>
            Star Catering Supplies Ltd is a well-established food-service
            specialist firm currently operating from Enfield, North of London.
          </p>
          <p>
            We offer the highest quality of products and services on the market
            today. Our 25 years of experience and commitment to excellence have
            earned us the reputation of one of the best food-service specialists
            in the industry. Our knowledge of the catering industry means that
            you can be sure of getting the service, value and support that you
            need. We have a large product portfolio consisting of over 1500
            products, which will give you a choice of quality and value for
            money on products.
          </p>
          <p>
            Through working with reputable suppliers and manufacturers we have
            become an important member of the catering supplies industry, and a
            valued member of the British Frozen Foods Federation. This
            association gives Star access to all legislative changes within the
            frozen food industry, supplying over 5000 outlets.
          </p>
          <p>
            With over 45 fully refrigerated multi-temperature vehicles we serve
            London and the Home Counties along with North of England, Bristol to
            the west, Portsmouth Down south and Suffolk to the East.
          </p>
          <p>
            We have a large customer portfolio ranging from fast-food outlets to
            pubs, leisure centers, care homes, theme parks, and zoos, etc.
          </p>
        </DivContent>
        <hr
          style={{
            width: "100%",
            border: "1px solid #D9D9D9",
            marginBottom: "14px",
          }}
        ></hr>
        <div>
          <h2>
            Collection Service -{" "}
            <span>Call or Order online and shop at your local branch.</span>
            &nbsp;
          </h2>
          <Image
            alt=""
            loading="lazy"
            title="test"
            src={"./images/Reception.jpg"}
          />
          <p style={{ marginLeft: "1rem" }}>
            Welcome to our reception area, where you can browse promotion
            brochures, view magnificent 7 offers on our TV Screen, and order
            with a member of our sales staff (All major cards accepted).
          </p>
        </div>

        <hr
          style={{
            width: "100%",
            border: "1px solid #D9D9D9",
            marginBottom: "14px",
          }}
        ></hr>
        <h2>Delivering value for all your needs...</h2>

        <ListDiv>
          <ul>
            <li>
              <img
                src="https://www.star-catering.co.uk/media/wysiwyg/about-us/delivery-option-1.jpg"
                alt="About Us"
              />
            </li>
            <li>Dedicated Sales Team</li>
            <li>Team of highly trained telesales personnel</li>
            <li>Ongoing special offers</li>
            <li>Point of sales material</li>
            <li>Informative and supportive area representative</li>
          </ul>
          <ul>
            <li>
              <img
                src="https://www.star-catering.co.uk/media/wysiwyg/about-us/delivery-option-2.jpg"
                alt="About Us"
              />
            </li>
            <li>Products</li>
            <li>
              Over 1,500 products to choose from - food products and non-food
              products
            </li>
            <li>
              Constant sourcing of innovative products - keeping up with the
              latest trends
            </li>
            <li>Star Value brands - unbeatable value for money</li>
            <li>Top brand names at the most competitive prices</li>
          </ul>
          <ul>
            <li>
              <img
                src="https://www.star-catering.co.uk/media/wysiwyg/about-us/delivery-option-3.jpg"
                alt="About Us"
              />
            </li>
            <li>Logistics</li>
            <li>The latest in frozen and chilled technology</li>
            <li>Fully racked ambient distribution centre</li>
            <li>Continuous monitoring and stock rotation</li>
            <li>Modern computerised loading bay management</li>
          </ul>
          <ul>
            <li>
              <img
                src="https://www.star-catering.co.uk/media/wysiwyg/about-us/Star-lorry-mock-up-2022-v3.jpg"
                alt="About Us"
              />
            </li>
            <li>Delivery</li>
            <li>Multi-temperature vehicles</li>
            <li>Uniformed drivers</li>
            <li>Friendly and efficient delivery direct to your door</li>
            <li>Deliveries throughout London, M25 and the Home Counties</li>
          </ul>
        </ListDiv>
      </InformationDiv>
    </Container>
  );
}

export default AboutContent;
