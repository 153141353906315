import styled from "styled-components";
import { colors } from "../../res/values/values";
import { useNavigate } from "react-router-dom";
import { formatUrlCategoryString } from "../../utilities/helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "../../redux/actions/categoryActions";
import { useEffect } from "react";
const CategoryDiv = styled.div`
  margin-top: 1rem;
  margin: 5px 10px;
`;

const CategoriesContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 0rem;
  

`;
const Divider = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #cacaca;
`;
const EachCategory = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
  margin: 1rem 1rem;
  border-radius: 10px;
  //padding-bottom: 1rem;
  
  cursor: pointer;
  & span {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.0015em;
    color: #797979;
    opacity: 0.9;
    text-align: center;
    @media only screen and (max-width: 768px) {
      font-size: 18px;
  }
  }
  &:hover {
    & span {
      opacity: 1;
      color: ${colors.primaryColor};
    }
    
  }
  @media only screen and (max-width: 768px) {
    width: 40%;
    margin: 0.5rem;
  }
`;
const Categories = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
`;
const EachCategoryImage = styled.img`
  width: 100%;
  height: 180px;
  border-radius: 1rem;
  object-fit: contain;
  opacity: .8;
  @media only screen and (max-width: 768px) {
    height: 100px;
  }
   &:hover{
    opacity: 1;
   }
`;
const Title = styled.span`
  width: auto;
  margin-top: 20px;
  //margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${colors.primaryColor};
  padding-left: 10px;
`;
function CategoriesCatalog(props) {
  const navigate = useNavigate()
  useEffect(()=>{
    const getCategories = async () =>{
      props.categories.length === 0 && await props.actions.getCategories();
    }
    getCategories()
  },[])
  const rootCategory =(route)=>{
    navigate(`/product-categories${route}`);
  /*
    navigate(`/product-categories/${cat.code.toLowerCase()}`);
    props.categories.map((cat) => {
      if (cat.code === catId) {
        //const descLabel = formatUrlCategoryString(cat.description);
        navigate(`/product-categories/${cat.code.toLowerCase()}`);
      }
    });
    */
    
 }
  return (
    <CategoryDiv>
      <Title>Categories</Title>
          <Divider />
        <CategoriesContent>
          
          <Categories>
            <EachCategory onClick={()=>rootCategory("/ambient/grocery/sauces-dressing")}>
              <EachCategoryImage  src="/images/S01.jpeg"></EachCategoryImage>
              <span>Sauces</span>
            </EachCategory>
            <EachCategory onClick={()=>rootCategory("/ambient/confectionery")}>
              <EachCategoryImage src="/images/S02.jpeg"></EachCategoryImage>
              <span>Confectionery</span>
            </EachCategory>
            <EachCategory onClick={()=>rootCategory("/frozen/ice-cream")}>
              <EachCategoryImage src="/images/D02.jpeg"></EachCategoryImage>
              <span>Ice Creams</span>
            </EachCategory>
            <EachCategory onClick={()=>rootCategory("/fresh/dairy")}>
              <EachCategoryImage src="/images/C03.jpeg"></EachCategoryImage>
              <span>Chilled</span>
            </EachCategory>
            <EachCategory onClick={()=>rootCategory("/fresh/dairy")}>
              <EachCategoryImage src="/images/D01.jpeg"></EachCategoryImage>
              <span>Dairy</span>
            </EachCategory>
            <EachCategory onClick={()=>rootCategory("/ambient/grocery")}>
              <EachCategoryImage src="/images/C04.jpeg"></EachCategoryImage>
              <span>Cooking Ingredients</span>
            </EachCategory>
          </Categories>
        </CategoriesContent>
      </CategoryDiv>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCategories: bindActionCreators(
        categoryActions.getCategories,
        dispatch
      ),
    },
  };
}

function mapStateToProps(state) {
  return {
    categories: state.categoryListReducer,
  };
}
export default connect(mapStateToProps,mapDispatchToProps)(CategoriesCatalog) ;
