import styled from "styled-components";

//#region Styles

const Container = styled.div`
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  padding: 10px;
`;
const PageTitle = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "myriad-pro", Arial, sans-serif;
  & p {
    color: #000;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400;
    margin: 0;
    padding-bottom: 10px;
    padding-top: 15px;
  }

  & h1 {
    color: #231F20;
    font-family: "myriad-pro",Arial,sans-serif;
    font-weight: 600;
    font-size: 2rem;
    border-bottom: 1px solid #EDEDED;
    padding-bottom: 3px;
    margin-bottom: 15px;
  }
`;
const DivStd = styled.div`
  display: block;
  & img {
    margin: 25px 0 15px;
    max-width: 100%;
  }
`;
const ExportServicesText = styled.div`
  margin: 0 0 30px;
  & p {
    color: #231F20;
    font-family: "myriad-pro",Arial,sans-serif;
    font-size: 14px;
    line-height: 24px;
    @media only screen and (max-width: 639px) {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
    }
    @media only screen and (max-width: 379px) {
        font-size: 18px;
    }
    & a {
        text-decoration: none;
        color: #D5393F;
    }
  }
`;

const ExportServicesTitle = styled.h2`
    margin: 0;
    margin-bottom: 0.5em;
    color: #39c;
    font-family: "myriad-pro",Arial,sans-serif;
    font-size: 22px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
`
//#endregion
function TermsConditionsContent() {
  return (
    <Container>
      <PageTitle>
        <h1>Terms &amp; Conditions</h1>
      </PageTitle>
      <DivStd>
        <ExportServicesText>
        <p>“We” “Us” “Our” means Star Catering Supplies Limited, a company registered in England. Company number 02839246, whose registered office is at Unit 1-8, Centenary Estate Jeffreys Rd Enfield London EN3 7UD.</p>
        </ExportServicesText>
        <ExportServicesText>
            <ExportServicesTitle>1. Orders</ExportServicesTitle>
            <p>By placing an order with us via our telesales department, the customer is offering to contract with us on the basis of these terms and conditions.</p>
            <p>The parties are bound by the contract only when we accept to process the order or deliver the products to the customer. The contract is subject to availability of the stock and market conditions.</p>
            <p>We may limit the quantities of goods supplied to any one customer.</p>
            <p>In the event of non-availability of any goods you may order, we may offer a reasonable substitute. This may affect the price you pay. You may reject any substitute item and we will refund the amount you have been charged for that substitute.</p>
            <p>Customers last orders for telesales is 5:30pm; the orders are processed for the next available delivery date.</p>
            <p>Calls made to telesales may be recorded for training purposes and we reserve the right to refuse the acceptance of an order and/or to terminate any customers trading account without explanation or notice.</p>
        </ExportServicesText>
        <ExportServicesText>
            <ExportServicesTitle>2. Minimum order</ExportServicesTitle>
            <p>Our minimum order value for delivery inside the M25 is £75 net value. For deliveries outside the M25 the minimum delivery charge varies according to the area.</p>
            <p>We reserve the right not to deliver an order which is valued at less than the latest minimum order value.</p>
        </ExportServicesText>

        <ExportServicesText>
            <ExportServicesTitle>3. Deliveries</ExportServicesTitle>
            <p>Deliveries of products to the customer shall be in accordance with our delivery schedule. We will not always guarantee delivery in accordance to the customers requested timing schedule.</p>
            <p>We shall not be liable for any loss or damage whatsoever arising as a result of failure to deliver products by a particular date or arising as a result of any causes beyond our control.</p>
            <p>The customer shall be present at the premises to take and sign off the delivery of the products. Deliveries will be made to the address specified by the customer when the account was created.</p>
            <p>Health and Safety standards must be met at all premises receiving delivery from us. Failure to do so may result in us being unable to place the delivery in the required area.</p>
            <p>We reserve the right to restrict deliveries in certain areas, and this includes the right to eliminate certain areas from our delivery schedule altogether.</p>
            <p>If the customer fails to take the delivery or fails to give us adequate delivery instructions of the time stated for delivery then without prejudice to any other right or remedy available to us, we may do one or more of the following:</p>
            <ul>
            <li><strong>3.1 -</strong> Charge the customer the cost of carriage of the refused delivery both to and from the premises of the customer in addition to our administration charges. A charge of £20.00 or 10% of the delivery value, which ever is greater, will be made.</li>
            <li><strong>3.2 -</strong> Charge full cost of price for perishable products.</li>
            <li><strong>3.3 -</strong> Charge the customer a rescheduling delivery charge.</li>
            </ul>
        </ExportServicesText>

        <ExportServicesText>
            <ExportServicesTitle>4. Shortages</ExportServicesTitle>
            <p>The number of packages delivered must be checked in the presence of the driver. Customers will sign off the delivery note highlighting the shortages countersigned by our driver. Claims for shortages should be made at the time of delivery.</p>
        </ExportServicesText>
        
        <ExportServicesText>
            <ExportServicesTitle>5. Risk and ownership</ExportServicesTitle>
            <p>All risks in the goods pass to the customer on delivery. Ownership of goods delivered to customers shall remain with us until payment of the invoiced cost has been received in full.</p>
        </ExportServicesText>

        <ExportServicesText>
            <ExportServicesTitle>6. Warranties</ExportServicesTitle>
            <p>We warrant that all food products and non-food products shall comply with all U.K and EU legislations.</p>
        </ExportServicesText>

        <ExportServicesText>
            <ExportServicesTitle>7. Price</ExportServicesTitle>
            <p>Contract price is invoiced price. Any offers are subject to availability and the prices quoted or shown on our literature are excluded of V.A.T.</p>
            <p>Limited quantities on selected products may apply. We reserve the right to amend these offers, prices and pack sizes at any time. Prices of some of the products may vary at Brighton depot. Please inquire. Not all products are available at Brighton depot. Every effort has been made to ensure the accuracy of these prices and special offers, however discrepancies may occur.</p>
            <p>V.A.T will be charged at the appropriate rate on goods where applicable.</p>
        </ExportServicesText>

        <ExportServicesText>
            <ExportServicesTitle>8. Payments</ExportServicesTitle>
            <p>Customers must settle all accounts in accordance with all agreed terms. In the event of a customer account being in arrears, we reserve the right to charge statutory interest on overdue balances for the period from the date on which the payment was due.</p>
            <p>The customer may choose to settle their account with debit or credit card. Debit and credit cards accepted by us are those listed in our promotional magazine or website. Authority for payment will be requested from your card issuer at the time of payment and your card will be charged accordingly.</p>
            <p>We reserve the right to terminate our agreement with you if we are refused authority for payment or reasonably believe that payment will be refused at any stage. In the event of any cheques, standing orders or direct debits due from a customer to us being dishonoured, a charge of £30 will be made on the customers account to cover the bank and administrative cost. For dishonoured cheques, customers terms will change to “cash on delivery” and the account will be placed on hold until cleared funds are received in full to settle the outstanding balance of the account.</p>
            <p>For credit account customers, any non-payment or late payment will be considered as a breach of the agreed terms, will render credit terms null and void. Customer account will be put on hold.</p>
            <p>We have the right to start legal proceedings to recover any outstanding amount without any prior notice and the right to exchange information with credit agencies.</p>
            <p>A maximum cash payment by coins allowed by any one customer is £60 in £1 or £2 coins only.</p>
        </ExportServicesText>

        <ExportServicesText>
            <ExportServicesTitle>9. Account Terms</ExportServicesTitle>
            <p>Initially all new accounts will be set as C.O.D (Cash on Delivery) for a minimum period of three months. We thereafter may apply for a credit account pending decision and subject to references and cross references with credit agencies. Customers will continue with current terms.</p>
            <p>All credit account invoices of one calendar month are due for payment on the 15th day of the following month, (e.g. All January invoices are due on the 15th of February).</p>
        </ExportServicesText>

        <ExportServicesText>
            <ExportServicesTitle>10. Returns</ExportServicesTitle>
            <p>We will only credit the customer if the wrong product was delivered and returned on the same day with the same delivery driver in original condition. Any other returns, our drivers are not authorised to accept goods to return without the appropriate documentation.</p>
        </ExportServicesText>

        <ExportServicesText>
            <ExportServicesTitle>11. General Provisions</ExportServicesTitle>
            <p>The contract and any claims arising in connection with it shall be governed by English law and any dispute between us will be resolved exclusively in the English courts.</p>
        </ExportServicesText>

        <ExportServicesText>
            <ExportServicesTitle>12. Disclaimer</ExportServicesTitle>
            <p>Please note: All offers are subject to availability. Limited quantities on selected products may apply. Prices shown are exclusive of VAT at 20% where applicable. We reserve the right to amend these offers, prices and pack sizes at any time. Every effort has been made to ensure the accuracy of these special offers, however discrepancies may occur. Our standard terms and conditions apply, which can be viewed on our website or copies are available on request. All images are for guidance purposes only. Design, including text and photography are the copyright of Star Catering Supplies Ltd and associated individuals &amp; companies. E &amp; OE. ©2020</p>
        </ExportServicesText>

      </DivStd>
    </Container>
  );
}

export default TermsConditionsContent;
