import React, { useState } from "react";

import styled from "styled-components";

//#region Styles

const Glide = styled.div`
  display: flex;
  min-width: 20%;
  height: 8rem;
  transition: 0.5s;
  justify-content: center;
  align-items: center;
  margin-inline: 0.5rem;
`;
const Poster = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: scale-down;
  opacity: 0.8;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

//#endregion
function BrandsCarouselGlide({ index, brand, rootBrands, value }) {
  const [visible, setVisible] = useState(true);
  const errorImage = () => {
    setVisible(false);
  };
  const renderGlide = () => {
    let component;
    if (visible) {
      component = (
        <Glide key={index} style={{ transform: `translateX(${value}%)` }}>
          <Poster
            key={brand.code}
            className="poster"
            src={`./catalog/Brands/${brand.code}.jpg`}
            onError={errorImage}
            onClick={() => rootBrands(brand.code)}
          />
        </Glide>
      );
    } else {
      component = <></>;
    }
    return component;
  };
  return (
    <>{renderGlide()}</>
  );
}

export default BrandsCarouselGlide;
