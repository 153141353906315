import styled from "styled-components";
import CardContent from "../components/PagesComponents/CardContent";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "../redux/actions/productActions";
import * as userActions from "../redux/actions/userActions";
import * as settingsActions from "../redux/actions/settingsActions";
import CircularProgress from "@mui/material/CircularProgress";
import { API_URL } from "../res/values/values";
import useWindowWidthAndHeight from "../utilities/hooks/useWindowWidthAndHeight";
import { Helmet } from "react-helmet";
import NoPage from "./NoPage";
const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
`;
const ContainerLoading = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 2rem 0.5rem;
  align-items: center;
  min-height: 40vw;
`;
function Card(props) {
  const [loading, setLoading] = useState(true);
  const [currentProduct, setCurrentProduct] = useState({});
  const [jsonLdData,setJsonLdData] = useState({})
  const [notfoundData,setNotFoundData] = useState(false)
  let params = useParams();
  // JSON-LD içeriği oluşturun
  useEffect(() => {
    loading && window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const getProduct = async () => {
      let paramList = params.productid.split("-");
      let id =paramList[paramList.length-2]  //params.productid.split("-").;
      let url = `${process.env.REACT_APP_API}/products/card/${id}?customerprice=${props.currentUser.customerpricegroup}&customerno=${props.currentUser.customerno}`;
      await fetch(url)
        .then((res) => res.json())
        .then((res) => {
          if (res.message.data.length === 0) {
            setNotFoundData(true)
          }else{
            setCurrentProduct(res.message.data[0]);
            setJsonLdData(
              {
                "@context": "https://schema.org/",
                  "@type": "Product",
                  "image": [
                    `http://www.edaqualityfoods.com/img/Products/${res.message.data[0].itemno}.jpg`
                  ],
                  "name": res.message.data[0].description,
                  "description": `Buy ${res.message.data[0].description} with best price and collection or delivery options from Star Catering Supplies.`,
                  "brand": {
                    "@type": "Brand",
                    "name": res.message.data[0].brandname
                  },
                  
              }
            )
          }
          
          setLoading(false);
        }).catch(ex =>{
        });
    };
    getProduct();
  }, [params.productid]);
  return (
    <Container>
      {!loading ? (
        notfoundData ? (
           <NoPage/>
        ) : (
          <>
          <Helmet>
            <title>
              Buy {currentProduct.description} | Star Catering Supplies
            </title>
            <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
            <meta
              name="description"
              content={
                "Buy " +
                currentProduct.description +
                " with best price and collection or delivery options from Star Catering Supplies."
              }
            />
            <link rel="canonical" href={document.URL}/>
            <meta name="robots" content="index, follow" />
          </Helmet>
          <CardContent
            key={currentProduct.product_id}
            product={currentProduct}
          />
        </>
        )
       
      ) : (
        <ContainerLoading>
          <CircularProgress size={40} thickness={4} />
        </ContainerLoading>
      )}
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    allProducts: state.allProductListReducer,
    currentUser: state.currentUserReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getAllProducts: bindActionCreators(
        productActions.getSearchProducts,
        dispatch
      ),
      getUser: bindActionCreators(userActions.getUser, dispatch),
      getSettings: bindActionCreators(settingsActions.getSettings, dispatch),
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Card);
