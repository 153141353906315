import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@material-ui/icons";
import styled from "styled-components";
import { colors, API_URL } from "../../res/values/values";
import { useNavigate } from "react-router-dom";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";
import ProductCardView from "./ProductCardView";
//#region Styles
const BodyDiv = styled.div`
  display: grid;
  //flex: 1;
  //flex-direction:column;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  //margin: 5px 10px; 
  //width:${(props) => props.width-100}px;
  
`;
const Glider = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 3px 15px -1px rgb(0 0 0 / 10%);
  padding: 10px 10px;
  border-bottom: 1px solid #f2f2f2;
`;
const Glide = styled.div`
  height: auto;
  transition: 0.5s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  @media only screen and (min-width: 600px) {
    min-width: 50%;
  }
  @media only screen and (min-width: 900px) {
    min-width: 30%;
  }
  @media only screen and (min-width: 1200px) {
    min-width: 23%;
  }
  @media only screen and (min-width: 1324px) {
    min-width: 20%;
  }
`;
const RightButton = styled.div`
  z-index: 2;
  position: absolute;
  color: ${(props) => (props.color === "black" ? "#000" : "#000")};
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  top: ${(props) => props.heightPosition}%;
  right: ${(props) => props.widthPosition}%;
  background-color: #fff;
  border-radius: 50%;
  //box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.4);
  height: 35px;
  cursor: pointer;
`;
const LeftButton = styled.div`
  z-index: 2;
  position: absolute;
  color: ${(props) => (props.color === "black" ? "#000" : "#000")};
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  top: ${(props) => props.heightPosition}%;
  left: ${(props) => props.widthPosition}%;
  background-color: #fff;
  border-radius: 50%;
  //box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.4);
  height: 35px;
  cursor: pointer;
`;

const TitleContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  
`;
const Title = styled.span`
  width: auto;
  margin-top: 20px;
  //margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${colors.primaryColor};
  padding-left: 10px;

`;
const TitleViewAll = styled.span`
  width: auto;
  margin-top: 20px;
  //margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${colors.primaryColor};
  padding-right: 2rem;
  &:hover {
    //background-color: ${colors.primaryColor};
    color:  ${colors.darkcolor};
    cursor: pointer;
  }
`;
const Divider = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #cacaca;
`;

//#endregion
function Carousal(props) {
  const [weboffers, setWebOffers] = useState([]);
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const { height, width } = useWindowWidthAndHeight();
  useEffect(() => {
    const getWebOffers = async () => {
      let url = "";
      if (props.type === "newitem")
        url = `${process.env.REACT_APP_API}/products/newitems?customerprice=${props.currentUser.customerpricegroup}&customerno=${props.currentUser.customerno}`;
      else
        url = `${process.env.REACT_APP_API}/products/weboffers?customerprice=${props.currentUser.customerpricegroup}&customerno=${props.currentUser.customerno}`;
        await fetch(url)
        .then((res) => res.json())
        .then((res) => {
          setWebOffers(res.message.data);
        });
    };
    getWebOffers();
  }, [props.currentUser]);

  const moveBehind = () => {
    const viewSize =
      width < 600
        ? 1
        : width < 900
        ? 2
        : width < 1200
        ? 3
        : width < 1324
        ? 4
        : 5;
    value === -100 * (weboffers.length - viewSize)
      ? setValue(0)
      : setValue(value - 100);
  };
  const moveAhead = () => {
    const viewSize =
      width < 600
        ? 1
        : width < 900
        ? 2
        : width < 1200
        ? 3
        : width < 1324
        ? 4
        : 5;
    value === 0
      ? setValue(-100 * (weboffers.length - viewSize))
      : setValue(value + 100);
  };
  if(weboffers.length>0) 
    return (
      <BodyDiv width={width}>
        <TitleContent>
          <Title>
            {props.type === "newitem" ? "New Products" : "Web Offers"}{" "}
          </Title>
          <TitleViewAll onClick={() =>
              props.type === "newitem"
                ? navigate("catalogsearch/result/?q=newitem")
                : navigate("catalogsearch/result/?q=weboffers")
            }>
            {" View All ... "}
          </TitleViewAll>
        </TitleContent>
          
          <Divider />
        <LeftButton onClick={moveAhead} heightPosition={45} widthPosition={-0.1}>
          <KeyboardArrowLeftOutlined fontSize="large" />
        </LeftButton>
        <RightButton onClick={moveBehind} heightPosition={45} widthPosition={-0.1}>
          <KeyboardArrowRightOutlined fontSize="large" />
        </RightButton>

        <Glider width={width}>
          {weboffers.map((product, index) => {
            return (
              <Glide key={index} style={{ transform: `translateX(${value}%)` }}>
                <ProductCardView
                  key={product.itemno}
                  product={product}
                  viewType={"Grid"}
                />
              </Glide>
            );
          })}
        </Glider>
      </BodyDiv>
    );
  else
    return(<BodyDiv/>)
}
function mapStateToProps(state) {
  return {
    currentUser: state.currentUserReducer,
  };
}
export default connect(mapStateToProps, null)(Carousal);
