import styled from "styled-components";
import BrandsCarousel from "../GlobalComponents/BrandList";
const Container = styled.div`
  display: block;
  padding: 1rem;
`;
const BrandsWrapper = styled.div`
  & h1 {
    margin: 0;
    margin-bottom: 0.7em;
    color: #231f20;
    font-family: "myriad-pro", Arial, sans-serif;
    font-size: 36px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
  }
  & p {
    margin: 0 0 1.5em;
    color: #231f20;
    font-family: "myriad-pro", Arial, sans-serif;
    font-size: 14px;
    line-height: 24px;
  }
`;
function HomeContent() {
  return (
    <Container>
      <BrandsWrapper>
        <h1>Brands</h1>
        <p>We work closely with leading suppliers to ensure that we can supply our customers with the best
        possible products and variety, which helps us to create our own large selection of brands that are
        only available at Star Catering Supplies Ltd.</p>
      </BrandsWrapper>
      <BrandsCarousel />
    </Container>
  );
}

export default HomeContent;
