import styled from "styled-components";
import FeaturesImage from "../HomeComponents/FeaturesImage"
import CategoriesCatalog from "../HomeComponents/CategoriesCatalog"
import BrandsCarousel from "../GlobalComponents/BrandsCarousel"
import Carousel from "../GlobalComponents/Carousel"
import { connect } from "react-redux";
const Container = styled.div`
  padding: 0px;
  margin: 0px;
`;
function HomeContent(props) {
  return (
    <Container>
      {!props.currentUser.franchisecustomer && <Carousel type={"weboffer"} />}
      <CategoriesCatalog/>
      {!props.currentUser.franchisecustomer && <Carousel type={"newitem"} />}
    </Container>
  );
}
function mapStateToProps(state) {
  return {
    currentUser: state.currentUserReducer,
  };
}
export default connect(mapStateToProps, null)(HomeContent);
