import { useEffect } from "react";
import { Divider } from "@material-ui/core";
import { LocalOffer, DragIndicator,Menu,Bookmark } from "@material-ui/icons";
import styled from "styled-components";
import DropDownMenu from "./DropDownMenu";
import MenuBarCategoriesContent from "./MenuBarCategoriesContent";
import { colors } from "../../res/values/values";
import { useNavigate } from "react-router-dom";
import  useWindowWidthAndHeight  from "../../utilities/hooks/useWindowWidthAndHeight";
import { connect } from "react-redux";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color:  white;
  color: ${colors.primaryColor};
  //border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
  padding: 10px;
  position: relative;
  
`;
const ContainerMobile = styled.div`
  display: flex;
  align-items: center;
  background-color:  white;
  color: ${colors.primaryColor};
  padding:10px;
  position: relative;
  border-top: 1px solid lightgray;
`;
const MenuItem = styled.div`
  color: ${colors.darkcolor};
  font-size: 1.2rem;
  letter-spacing: 1px;
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;
  & :hover {
    color: ${colors.primaryColor};
  }
`;

function MenuBar(props) {
  const navigate = useNavigate();
  const { width } = useWindowWidthAndHeight();
  useEffect(() => {
  }, []);
  const renderMenuBarComponent = () => {
    let renderMenuBar;

    if (width > 480) {
      renderMenuBar = (
        <Container>
          <DropDownMenu
            color={colors.darkcolor}
            fontSize="1rem"
            fontWeight="600"
            top="1.5rem"
            icon={<Menu />}
            text="CATEGORIES"
            contentLinks={<MenuBarCategoriesContent />}
          />
          <Divider
            orientation="vertical"
            style={{ margin: "0 10px", background: "#f2f2f2" }}
          />
          {!props.currentUser.franchisecustomer && (
            <>
              <MenuItem
              onClick={() => {
                navigate("catalogsearch/result/?q=weboffers")
              }}
              >
                <LocalOffer style={{ paddingRight: "3px" }} /> <p style={{marginBottom:"0"}}>OFFERS</p>
              </MenuItem>
              <Divider
                orientation="vertical"
                style={{ marginLeft: "10px", background: "#f2f2f2" }}
              />
            </>
            
          )}
          
          <MenuItem
            onClick={() => {
              navigate("/brands");
            }}
          >
            <Bookmark style={{ paddingRight: "3px" }} /> <p style={{marginBottom:"0"}}>BRANDS</p>
          </MenuItem>
        </Container>
      );
    } else {
      renderMenuBar = (
        <ContainerMobile>
          <DropDownMenu
            color={colors.primaryColor}
            fontSize="1rem"
            fontWeight="600"
            top="0rem"
            left="0rem"
            icon={<Menu />}
            text=""
            contentLinks={"mobileMenu"}
          />
        </ContainerMobile>
      );
    }

    return renderMenuBar;
  };
  return (
    <div>
      {renderMenuBarComponent()}
    </div>
  );
}
function mapStateToProps(state) {
  return {
    currentUser: state.currentUserReducer,
  };
}
export default connect(mapStateToProps, null)(MenuBar);
