import styled from "styled-components";

//#region Styles

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  & img {
    max-width: 100%;
  }
  & p {
    margin: 0 0 1.5em;
}
`;
const InnerContentWrapper = styled.div`
    float: left;
    position: relative;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
`;
const InnerContentTextWrapper = styled.div`
    left: 80%;
    max-width: 500px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    & h1 {
      color: #fff;
      font-family: "Gill Sans",GillSans,Arial,sans-serif;
      font-size: 46px;
      font-weight: bold;
      text-transform: uppercase;
      @media only screen and (max-width: 989px) {
        font-size: 36px;
        color: #000;
      }
    }
    & h4 {
      color: #fff;
      font-family: "myriad-pro",Arial,sans-serif;
      font-size: 29px;
      font-weight: bold;
      margin: 0 auto;
      max-width: 368px;
      text-transform: uppercase;
      @media only screen and (max-width: 989px) {
        font-size: 19px;
        color: #000;
      }
    }
    @media only screen and (max-width: 989px) {
      max-width: none;
      position: unset;
      transform: none;
    }
`;
const LogoSection = styled.div`
  margin-bottom: 1rem;
  & img {
    display: block;
    margin: 0 auto;
    padding-top: 20px;
  }
`;
const CateringSuppliesContent = styled.div`
    
    & h2 {
      color: #14274c;
      font-family: "myriad-pro",Arial,sans-serif;
      font-size: 41px;
      font-weight: bold;
      margin: 0 auto;
      max-width: 1185px;
      padding-bottom: 40px;
      padding-top: 40px;
      text-align: center;
    }
    & h4 {
      color: #fff;
      font-family: "myriad-pro",Arial,sans-serif;
      font-size: 29px;
      font-weight: bold;
      margin: 0 auto;
      max-width: 368px;
      text-transform: uppercase;
    }
   
`;
const CateringSuppliesContentList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`
const CateringSuppliesContentListElement = styled.div`
    
    display: flex;
    flex: 1;
    flex-direction: column;
    margin:.5rem;
   
    & p {
      color: #000;
      font-family: "myriad-pro",Arial,sans-serif;
      font-size: 20px;
    }
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
    & img {
      height: 506px;
      width: 100%;
      @media only screen and (max-width: 989px) {
        height: auto;
      }
    }
    & h4 {
      color: #14274c;
      font-family: "myriad-pro",Arial,sans-serif;
      font-size: 37px;
      font-weight: bold;
      margin-bottom: 20px;
      padding-top: 20px;
      text-align: center;
    }
`;
//#endregion
function NewsContent() {
  return (
    <Container>
      <InnerContentWrapper>
          <img src="https://www.star-catering.co.uk/media/wysiwyg/bulletin/banner-image.png" alt="banner image" title="banner-image" />
          <InnerContentTextWrapper>
            <h1>Star Catering is investing in the future</h1>
				    <h4>We are investing to improve your business</h4>
          </InnerContentTextWrapper>
        </InnerContentWrapper>
        <LogoSection>
            <img src="https://www.star-catering.co.uk/media/wysiwyg/bulletin/logos.png" alt="Logo Images" title="Logo Images"/>
        </LogoSection>
        <CateringSuppliesContent>
          <h2>STAR CATERING SUPPLIES LTD CHOOSES MAN &amp; ISUZU TRUCKSTO DELIVER THE GOODS</h2>
          <CateringSuppliesContentList>
            <CateringSuppliesContentListElement>
              <p>Operating from two sites located in Enfield, in North London, Star Catering Supplies Ltd have recently invested in a fleet of new delivery vehicles. Based on the popular Man TGL 12 tonne truck &amp; the Isuzu 7.5 tonne truck chassis the new vehicles feature purpose built multi-temperature refrigerated bodies. Specifically designed to keep the transported food products at optimum temperature, the new vehicles will ensure that our range of 1500 quality products arrive in prime condition. </p>
			        <p>The new vehicles will serve the needs of our customers both in London and the Home Counties and further afield across the North of England, Bristol and the West Country, Portsmouth and the South Coast and, throughout Suffolk and the East. </p> 
            </CateringSuppliesContentListElement>
            <CateringSuppliesContentListElement>
              <p>To ensure that we meet and comply with the both the latest European legislation and the ULEZ (London Ultra Low emissions standard. This means that our produce arrives at your business in a planned and environmentally friendly way</p>
              <p>Designed using the most futuristic vehicle manufacturing processes, the new fleet of MAN &amp; Isuzu vehicles excels in delivering Star Catering Supplies high levels of fuel efficiency, industry leading levels of both passive and active safety, Intergrated cameras and enhanced standards of driver comfort.</p>
            </CateringSuppliesContentListElement>
          </CateringSuppliesContentList>
          <CateringSuppliesContentList>
            <CateringSuppliesContentListElement>
              <img src="https://www.star-catering.co.uk/media/wysiwyg/bulletin/star-van-left.png" alt="Star Van" title="Star Van"/>
            </CateringSuppliesContentListElement>
            <CateringSuppliesContentListElement>
              <img src="https://www.star-catering.co.uk/media/wysiwyg/bulletin/star-van-right.png" alt="Star Van" title="Star Van"/>
            </CateringSuppliesContentListElement>
          </CateringSuppliesContentList>
          <CateringSuppliesContentList>
            <CateringSuppliesContentListElement>
              <h4>MAXOPTRA</h4>
              <p>Maxoptra helps forward thinking businesses and brand to deliver incredible customer experiences through simplified route optimisation, smarter fleet management, innovative customer communications and and overall fast and reliable delivery service.</p>
              <p>Our dynamic routing functionality plans routes daily, weekly, monthly, or however frequently you require. Maxoptra not only takes into account traffic conditions and known roadworks but also vehicle capacities, live order volumes and delivery time windows. This helps reduce excess mileage.</p>
              <p>With Maxoptra you can proactively send out email and SMS ETA notifications to keep your customers informed. Customers can also keep themselves informed using our Website Widget. In addition, our ePOD functionality allows drivers to log proof of delivery and capture signatures, photos and comments.</p>
              <p>Maxoptra continuously compares planned performance with realtime data. The actual route is displayed next to the planned one right on the map; highlighting any route deviations. Any issues can be easily highlighted and quickly dealt with.</p>
            </CateringSuppliesContentListElement>
            <CateringSuppliesContentListElement>
              <img src="https://www.star-catering.co.uk/media/wysiwyg/bulletin/map.png" alt="Map" title="Map"/>
            </CateringSuppliesContentListElement>
          </CateringSuppliesContentList>

          <CateringSuppliesContentList>
            <CateringSuppliesContentListElement>
              <h4>FLEET TRAK</h4>
			        <p>Fleet Trak Limited has spent a decade building up a reputation for excellent telematic solutions to help fleet managers improve the performance of their fleet. We are a Certified Business Partner for TomTom Telematics fl eet management, connected navigation and vehicle tracking solutions and are one of the UK’s largest suppliers and installers of GPS technology.<br/>Our support staff have more than 30 years combined experience in the vehicle telematics industry. Fleet Trak manage over 12,500 vehicles, spread over 400+ customers, covering a broad array of industries across the country, but our quality of service remains the same with no short cuts taken. We leverage our national network of industry-approved engineers to provide short response times, wherever you are. This will allow our customers full traceability enabling them to track their orders.</p>
           </CateringSuppliesContentListElement>
            <CateringSuppliesContentListElement>
              <img src="https://www.star-catering.co.uk/media/wysiwyg/bulletin/map-devices.png" alt="Map" title="Map"/>
            </CateringSuppliesContentListElement>
          </CateringSuppliesContentList>
          <h2>FIVE-STAR SERVICE FROM STAR CATERING WITH DYNAMICS 365 BUSINESS CENTRAL</h2>
          <CateringSuppliesContentList>
            <CateringSuppliesContentListElement>
              <img src="https://www.star-catering.co.uk/media/wysiwyg/bulletin/microsoft.png" alt="Microsoft 365" title="Microsoft 365"/>
           </CateringSuppliesContentListElement>
            <CateringSuppliesContentListElement>
              <p>Aiming to improve its service quality, Star Catering Supplies Ltd. of the UK decided to migrate to Microsoft Dynamics 365 Business Central with the consultancy of Pargesoft.</p>
              <p>Operating in the UK with two locations for 30 years, Star Catering Supplies Ltd. is among the leading companies of the catering industry with its experience as well as its extensive range of over 1,500 products. Star Catering serves more than 5,000 customers with its high-quality products and services. </p>
              <p>Aiming to be prepared for the future and to further improve its service quality, Star Catering decided to benefit from the innovations offered by digital transformation and thus to migrate its ERP system to Microsoft Dynamics 365 Business Central. The company selected Microsoft Dynamics Gold Partner Pargesoft for consultancy and management support during migration, by counting on Pargesoft’s 20 years of experience in Microsoft Dynamics ERP solutions.</p>
              <p>Within the scope of the project launched in August 2019, Microsoft Dynamics 365 Business Central’s Sales, Marketing, Supply Chain Management, Warehouse Automation, Mobile Sales, Finance, and various other modules will be deployed. With support from the experienced team and product competence of Pargesoft, the project is expected to be completed by the end of 2019.</p>
              <p>With this project, Star Catering will employ technology to support its vision of customer-focused service quality and to improve both efficiency and customer satisfaction.</p>
            </CateringSuppliesContentListElement>
          </CateringSuppliesContentList>
        </CateringSuppliesContent>
        <LogoSection>
            <img src="https://www.star-catering.co.uk/media/wysiwyg/bulletin/logos.png" alt="Logo Images" title="Logo Images"/>
        </LogoSection>
    </Container>
        
  );
}

export default NewsContent;
