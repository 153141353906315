import styled from "styled-components";
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {colors} from "../../res/values/values"
//#region styles
const Accordion = styled.div`
  max-width: 600px; 
  font-size: 1.1rem;
  letter-spacing: 1.5px;
  font-weight: 300;
  margin-bottom: 5px;
  margin-top: 1rem;
  border: 1px solid lightgray;
`;
const AccordionDiv = styled.div`
  max-height:35vw ;
  overflow-y: auto;
  white-space: nowrap;
  @media only screen and (max-width: 600px) {
    max-height:50vw ;
  }
`;
const MainItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 1rem;
  color:  ${colors.primaryColor};
  font-size: 1.1rem;
  font-weight:800 ;
  letter-spacing: 1px;
  //margin-top: 5px;
  
  //border-width: 0 0px 1px 0px;
  //border-color: lightgray;
  //border-style: solid;
  overflow-wrap: anywhere;
  & h1{
    font-size: 1.1rem;
    font-weight:800 ;
    letter-spacing: 1px;
  }
  &:hover {
    background-color: ${colors.primaryColor};
    color:${colors.whiteColor}
  }
`;
const SubItem = styled.h2`
  padding: 15px 20px;
  color: #333;
  font-size: 0.9rem;
  font-weight:700 ;
  letter-spacing: 1px;
  //border-width: 0 0px 1px 0px;
  //border-color: lightgray;
  //border-style: solid;
  cursor: pointer;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  &:hover {
    background-color: ${colors.primaryColor};
    color:${colors.whiteColor}
  }
`;
const Return = styled.div`
  background-color:  ${colors.whiteColor};
  padding: 8px 10px;
  color: ${colors.primaryColor};
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  border-width: 0 0px 1px 0px;
  border-color: lightgray;
  border-style: solid;
  cursor: pointer;
  &:hover {
    background-color: ${colors.primaryColor};
    color:${colors.whiteColor}
  }
`;
const SelectSubItem = styled.div`
  padding: 15px 20px;
  font-size: 0.9rem;
  font-weight:700 ;
  letter-spacing: 1px;
  background-color: ${colors.primaryColor};
  color:${colors.whiteColor};
  border-width: 0 0px 1px 0px;
  border-color: lightgray;
  border-style: solid;
  cursor: pointer;
  overflow-wrap: anywhere;
`;
//#endregion
function AccordionMenu(props) {
  const navigate = useNavigate()
  const [isActive, setIsActive] = useState( props.item.type!=='Brands');

  const data = props.item;
  useEffect(()=>{
    
  },[])
  
  const changeIsActiveStatus =() =>{
    setIsActive(!isActive) 
  }
  const selectedSubItem = (item)=>{
    //${props.selectedSubCategory ? `&b_subcat=${props.selectedSubCategory.code}`:''}${props.filter && `&q=${props.filter}`} selectedProductIdCategory
    if(data.type==="ProductGroup"){
      if(!props.filter){
        if(!props.brand){
          //let catLabel = formatUrlCategoryString(data.title)
          //let productLabel = formatUrlCategoryString(item.description)
          navigate(`/product-categories/${data.parentid.toLowerCase()}/${item.code.toLowerCase()}`);
        }else{
          navigate(`/catalogsearch/result/?cat=${data.parentid.toLowerCase()}&subcat=${item.code.toLowerCase()}${props.brand ? `&brand=${props.brand}`:''}`)
        }
      }else{
        navigate(`/catalogsearch/result/?cat=${data.parentid.toLowerCase()}&subcat=${item.code.toLowerCase()}${props.filter && `&q=${props.filter}`}${props.brand ? `&brand=${props.brand}`:''}`)
      }
      
    }
    else if(data.type==="SubProductGroup"){
      if(!props.filter){
        if(!props.brand){
          //let catLabel = formatUrlCategoryString(data.title)
          //let productLabel = formatUrlCategoryString(item.description)
          let test = `/product-categories/${props.selectedCategory.code.toLowerCase()}/${data.parentid.toLowerCase()}/${item.code.toLowerCase()}`
          navigate(test);
        }else{
          navigate(`/catalogsearch/result/?cat=${props.selectedCategory.code.toLowerCase()}&subcat=${data.parentid.toLowerCase()}&b_subcat=${item.code.toLowerCase()}${props.brand ? `&brand=${props.brand}`:''}`)
        }
      }else{
        navigate(`/catalogsearch/result/?cat=${props.selectedCategory.code.toLowerCase()}&subcat=${data.parentid.toLowerCase()}&b_subcat=${item.code.toLowerCase()}${props.filter && `&q=${props.filter}`}${props.brand ? `&brand=${props.brand}`:''}`)
      }
      
    }
    else if(data.type==="Brands"){
      navigate(`/catalogsearch/result/?${props.selectedCategory ? `cat=${props.selectedCategory.code.toLowerCase()}`:''}${props.selectedSubCategory? `&subcat=${props.selectedSubCategory.code.toLowerCase()}`:''}${props.selectedProductIdCategory? `&b_subcat=${props.selectedProductIdCategory.code.toLowerCase()}`:''}${props.filter ? `&q=${props.filter}`:''}&brand=${item.code}`)
    }
    else if(data.type==="Category"){
      if(!props.filter){
        if(!props.brand){
          //let catLabel = formatUrlCategoryString(item.description)
          navigate(`/product-categories/${item.code.toLowerCase()}`);
        }else{
          navigate(`/catalogsearch/result/?cat=${item.code.toLowerCase()}${props.brand ? `&brand=${props.brand}`:''}`)
        }
        
      }else{
        navigate(`/catalogsearch/result/?cat=${item.code.toLowerCase()}${props.filter && `&q=${props.filter}`}${props.brand ? `&brand=${props.brand}`:''}`)
      }
      
    }
    
  }
  const returnPreviousCategory = ()=>{ 
      
    //navigate(`/route=search?${filter ? `&filter=${filter}` : ''}${brand ? `&brand=${brand}` : ''}${country ? `&country=${country}` : ''}`) 
  }
  return (
    <Accordion>
      <MainItem onClick={() => changeIsActiveStatus()}>
        {(data.type === 'ProductGroup' || data.type === 'SubProductGroup') ? <h1>{data.title}</h1> : <div>{data.title}</div>}
        <div>{isActive ? "-" : "+"}</div>
      </MainItem>
      <AccordionDiv>
        {isActive &&
          data.content.map((value) => {
            if(  (props.selectedSubCategory &&  value.code === props.selectedSubCategory.code) || value.code === props.brand || (props.selectedProductIdCategory &&  value.code === props.selectedProductIdCategory.code) ){
              return <SelectSubItem onClick={()=>{selectedSubItem(value)}} key={value.code}>{(data.type==='Brands' || data.type==='Country') ? value.code[0].toUpperCase() + value.code.slice(1).toLowerCase(): value.description[0].toUpperCase() + value.description.slice(1).toLowerCase()}</SelectSubItem>;
            }
            else
              return <SubItem onClick={()=>{selectedSubItem(value)}} key={value.code}>{(data.type==='Brands' || data.type==='Country') ? value.code[0].toUpperCase() + value.code.slice(1).toLowerCase(): value.description[0].toUpperCase() + value.description.slice(1).toLowerCase()}</SubItem>;
          })
        }
        
      </AccordionDiv>
    </Accordion>
  );
}

export default AccordionMenu;
//{(data.type==="ProductGroup" && isActive) && <Return onClick={()=>{returnPreviousCategory()}}> Return to Previous</Return>}
