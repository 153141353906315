/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components";
import { useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "../redux/actions/productActions";
import * as userActions from "../redux/actions/userActions";
import * as settingsActions from "../redux/actions/settingsActions";
import * as manufacturerActions from "../redux/actions/manufacturerActions";
import * as categoryActions from "../redux/actions/categoryActions";
import * as countryActions from "../redux/actions/countryActions";
import { colors } from "../res/values/values";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import useWindowWidthAndHeight from "../utilities/hooks/useWindowWidthAndHeight";
import LeftSide from "../components/GlobalComponents/LeftSide";
import Products from "../components/GlobalComponents/Products";
import { Helmet } from "react-helmet";
import ca from "date-fns/locale/ca/index";

//#region Sytles
const Container = styled.div`
  justify-content: space-between;
`;
const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  @media only screen and (min-width: 767px) {
    flex-direction: row;
  }
`;

const BreadCrumbTextHome = styled.a`
  font-size: 0.9rem;
  font-weight: 700;
  color: ${colors.primaryColor};
  padding: 5px;
  border-width: 0px 0px 1px 0px;
  border-color: lightgray;
  border-style: solid;
  @media only screen and (min-width: 768px) {
    border-width: 0px 1px 0px 0px;
    padding: 2px;
    margin-left: 10px;
    padding-right: 10px;
  }
`;
const BreadCrumbText = styled.a`
  font-size: 0.8rem;
  font-weight: 500;
  color: black;
  padding: 5px;
  border-width: 0px 0px 1px 0px;
  border-color: lightgray;
  border-style: solid;
  @media only screen and (min-width: 768px) {
    border-width: 0px 1px 0px 0px;
    padding: 2px;
    margin-left: 10px;
    padding-right: 10px;
  }
`;
const TwoColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 40vw;
  @media only screen and (min-width: 600px) {
    flex-direction: row;
  }
`;
const ContainerLoading = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 2rem 0.5rem;
  align-items: center;
  min-height: 40vw;
`;
const CategoryDescription = styled.h3`
  font-weight: normal;
  color: #797979;
  background-color: #fff;
  //padding: 1.2rem 2rem;
  font-size: 1rem;
  line-height: 1.6rem;
  margin: 0.2rem 0.2rem 1rem 0.2rem;
`;
//#endregion
function Saerch(props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [productLoading, setProductLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listType, setListType] = useState("Grid");
  const [brand, setBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProductIdCategory, setSelectedProductIdCategory] =
    useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [categoryDescription, setCategoryDescription] = useState('');
  const navigate = useNavigate();
  let params = useParams();
  useEffect(() => {
    const renderPage = async () => {
      await props.actions.getSettings();
      await props.actions.getCategories();
      const access_token = localStorage.getItem("access_token");
      if (access_token) await props.actions.getUser();
      if (params.categoryid) {
        let l_SelectedCategory = props.categories.filter(
          (c) => c.code.toUpperCase() === params.categoryid.toUpperCase()
        );
        l_SelectedCategory && setSelectedCategory(l_SelectedCategory[0]);
      } else {
        setSelectedCategory(null);
        setSelectedProductIdCategory(null);
        setSelectedSubCategory(null);
      }
      if (params.productid) {
        let l_SelectedProduct = props.categories.filter(
          (c) => c.code.toUpperCase() === params.productid.toUpperCase()
        );
        l_SelectedProduct && setSelectedProductIdCategory(l_SelectedProduct[0]);
      } else {
        setSelectedProductIdCategory(null);
        setSelectedSubCategory(null);
      }
      if (params.subproductid) {
        let l_SelectedSubProduct = props.categories.filter(
          (c) => c.code.toUpperCase() === params.subproductid.toUpperCase()
        );
        l_SelectedSubProduct && setSelectedSubCategory(l_SelectedSubProduct[0]);
      } else {
        setSelectedSubCategory(null);
      }
      setBrand(searchParams.get("brand"));
      searchParams.get("page") ? setPage(searchParams.get("page")) : setPage(0);
      searchParams.get("rowsPerPage")
        ? setRowsPerPage(searchParams.get("rowsPerPage"))
        : setRowsPerPage(24);
      searchParams.get("listType") && setListType(searchParams.get("listType"));
      setProductLoading(false);
      setLoading(false);
    };
    setProductLoading(true);
    renderPage();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [
    params.categoryid,
    params.productid,
    params.subproductid,
    searchParams,
    brand,
  ]);
  return (
    <div>
      {!loading ? (
        <Container>
          {selectedCategory ? (
            <Helmet>
              <title>
                {selectedCategory.description} | Star
                Catering Supplies
              </title>
              <meta
                name="description"
                content={
                  "You can order the products in the " +
                  selectedCategory.description +
                    " category by browsing the prices."
                }
              />
              <link rel="canonical" href={document.URL}></link>
              <meta name="robots" content="index, follow" />
            </Helmet>
          ) : (
            <Helmet>
              <title>
                 | Star Catering Supplies
              </title>
              <meta
                name="description"
                content={
                  "You can order the products in the all category by browsing the prices."
                }
              />
              <link rel="canonical" href={document.URL}></link>
              <meta name="robots" content="index, follow" />
            </Helmet>
          )}
          <Row>
            <BreadCrumbTextHome
              onClick={() => {
                navigate("/");
              }}
            >
              HOME
            </BreadCrumbTextHome>
            {selectedCategory && (
              <BreadCrumbText> {selectedCategory.description} </BreadCrumbText>
            )}
            {selectedProductIdCategory && (
              <BreadCrumbText>
                {" "}
                {selectedProductIdCategory.description}{" "}
              </BreadCrumbText>
            )}
            {selectedSubCategory && (
              <BreadCrumbText>
                {" "}
                {selectedSubCategory.description}{" "}
              </BreadCrumbText>
            )}
            {brand && <BreadCrumbText> {brand} </BreadCrumbText>}
          </Row>
          <TwoColumnContainer>
            <LeftSide
              filter={null}
              brand={brand}
              country={null}
              selectedCategory={selectedCategory}
              selectedProductIdCategory={selectedProductIdCategory}
              selectedSubCategory={selectedSubCategory}
            />

            {!productLoading ? (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <Products
                  filter={null}
                  brand={brand}
                  country={null}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  listType={listType}
                  selectedCategory={selectedCategory}
                  selectedProductIdCategory={selectedProductIdCategory}
                  selectedSubCategory={selectedSubCategory}
                />

                {(selectedSubCategory) ? (
                  <div style={{ margin: "2rem 0.3rem", alignItems: "center" }}>
                    <h2 style={{ textAlign: "center", color: "#797979" }}>
                      {selectedSubCategory.categorydescription!=='' && selectedSubCategory.code}
                    </h2>

                    <CategoryDescription
                      dangerouslySetInnerHTML={{
                        __html: selectedSubCategory.categorydescription,
                      }}
                    />
                  </div>
                ) : (selectedProductIdCategory) ?(
                  <div style={{ margin: "2rem 0.3rem", alignItems: "center" }}>
                    <h2 style={{ textAlign: "center", color: "#797979" }}>
                      {selectedProductIdCategory.categorydescription!=='' && selectedProductIdCategory.code}
                    </h2>

                    <CategoryDescription
                      dangerouslySetInnerHTML={{
                        __html: selectedProductIdCategory.categorydescription,
                      }}
                    />
                  </div>
                ) : (selectedCategory) && (
                  <div style={{ margin: "2rem 0.3rem", alignItems: "center" }}>
                    <h2 style={{ textAlign: "center", color: "#797979" }}>
                      {selectedCategory.categorydescription!=='' && selectedCategory.code}
                    </h2>

                    <CategoryDescription
                      dangerouslySetInnerHTML={{
                        __html: selectedCategory.categorydescription,
                      }}
                    />
                  </div>
                )
                }
              </div>
            ) : (
              <ContainerLoading>
                <CircularProgress size={40} thickness={4} />
              </ContainerLoading>
            )}
          </TwoColumnContainer>
        </Container>
      ) : (
        <ContainerLoading>
          <CircularProgress size={40} thickness={4} />
        </ContainerLoading>
      )}
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      changeSearch: bindActionCreators(productActions.changeSearch, dispatch),
      getUser: bindActionCreators(userActions.getUser, dispatch),
      getSettings: bindActionCreators(settingsActions.getSettings, dispatch),
      getManufacturers: bindActionCreators(
        manufacturerActions.getManufacturerList,
        dispatch
      ),
      getCategories: bindActionCreators(
        categoryActions.getCategories,
        dispatch
      ),
      getCountryList: bindActionCreators(
        countryActions.getCountryList,
        dispatch
      ),
    },
  };
}
function mapStateToProps(state) {
  return {
    categories: state.categoryListReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Saerch);

/*
<Products
                  categoryid={categoryid}
                  productid={productid}
                  filter={null}
                  brand={brand}
                  country={null}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  listType={listType}
                  categoryDesc={categoryDesc}
                  productDesc={productDesc}
                />
                {selectedCategory.categorydescription!=='' && (
                  <div style={{ margin: "0rem 0.3rem", alignItems: "center" }}>
                    <h2 style={{ textAlign: "center", color: "#797979" }}>
                      Category Description
                    </h2>

                    <CategoryDescription
                      dangerouslySetInnerHTML={{
                        __html: selectedCategory.categorydescription,
                      }}
                    />
                  </div>
                )}
*/
