import styled from "styled-components";

//#region Styles

const Container = styled.div`
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  padding: 10px;
`;
const PageTitle = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "myriad-pro", Arial, sans-serif;
  & p {
    color: #000;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400;
    margin: 0;
    padding-bottom: 10px;
    padding-top: 15px;
  }

  & h1 {
    margin-top: 1rem;
    border-bottom: none;
    font-size: 30px;
    font-weight: 600;
  }
`;
const DivStd = styled.div`
  display: block;
  & img {
    margin: 25px 0 15px;
    max-width: 100%;
  }
`;
const ExportServicesText = styled.div`
  margin: 0 0 30px;
  & p {
    color: #000;
    font-size: 18px;
    font-style: normal !important;
    font-weight: 500;
    margin: 0;
    padding-bottom: 10px;
    padding-top: 15px;
    @media only screen and (max-width: 639px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 379px) {
      font-size: 18px;
    }
    & a {
      text-decoration: none;
      color: #d5393f;
    }
  }
`;
const ExportServicesTitle = styled.p`
  font-size: 26px;
  font-style: normal !important;
`;
//#endregion
function ExportServicesContent() {
  return (
    <Container>
      <PageTitle>
        <h1>Export Services</h1>
      </PageTitle>
      <DivStd>
        <img
          src="https://www.star-catering.co.uk/media/wysiwyg/export-services/export_services.jpg"
          alt=""
        />
        <ExportServicesText>
          <p>
            Star Catering Supplies Ltd is a well-established catering and
            food-service specialist currently operating from two sites located
            in Enfield (North of London). We are now well located to provide
            export organisations with our wide range of products.
          </p>
          <p>
            <img
              src="https://www.star-catering.co.uk/media/wysiwyg/export-services/BFFF_logo.jpg"
              style={{ float: "right" }}
              alt="Member of the British Frozen Food Federation"
            />
            Through working with reputable suppliers or manufacturers we have
            become an important member of the catering supplies industry, and a
            valued member of the British Frozen Foods Federation. This
            association gives Star access to all legislative changes within the
            frozen food industry Supplying over 5000 outlets.
          </p>
          <p>
            To create an account please send us an email at:{" "}
            <a href="mailto:export@starcateringuk.com">
              export@starcateringuk.com
            </a>{" "}
            or <a href="tel:+4402085272666">Call: +44 (0) 20 8527 2666</a>
          </p>
        </ExportServicesText>
        <ExportServicesText>
          <ExportServicesTitle>What We Do</ExportServicesTitle>
          <p>
            As a food-service specialist we have the highest quality of products
            and services on the market today. Our 34 years of experience and
            commitment to excellence have earned us the reputation as one of the
            best food-service specialist in the industry. Our knowledge of the
            catering industry means that you can be sure of getting the service,
            value and support that you need. We have a large product portfolio
            consisting of over 1500 products, which will give you a choice of
            quality and value for money on products.
          </p>
        </ExportServicesText>
        <ExportServicesText>
          <ExportServicesTitle>Quality Assured</ExportServicesTitle>
          <p>
            Star Catering Supplies Ltd achieved Quality (ISO 9001),
            Environmental (ISO 14001), Health &amp; Safety (OHSAS 18001) and
            HACCP Management Systems certification withying colours.
          </p>
          <p>
            By implementing all these Best Practice Management Systems which
            drives efficiency on all aspects of our business creates savings and
            by achieving savings enables us to offer goods and services that has
            unquestionable ‘Value for Money’ offering to our customers.
          </p>
          <p>
            For more information about our Certifications -{" "}
            <a href="quality-assurance">click here</a>
          </p>
        </ExportServicesText>
      </DivStd>
    </Container>
  );
}

export default ExportServicesContent;
