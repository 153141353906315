import React from "react";
import styled from "styled-components";
import ExportServicesContent from "../components/PagesComponents/ExportServicesContent";
import { Helmet } from "react-helmet";
import { redirect,useNavigate,redirectDocument } from "react-router-dom";
import { useEffect } from "react";
const Container = styled.div`
  //width: ${(props) => props.width}px;
`;
const ExportServices = () => {
  const navigation = useNavigate()
  useEffect(()=>{window.scrollTo({ top: 0, left: 0, behavior: "smooth" });},[])
  return (
    <Container>
      <Helmet>
        <title>Export Services | Star Catering UK Supplies</title>
        <meta name="description" content="Export Services | Star Catering UK Supplies" />
        <meta name="robots" content="index, follow"/>
        <link rel="canonical" href={document.URL}/>
      </Helmet>
      <ExportServicesContent />
    </Container>
  );
};

export default ExportServices;
