import React, { useEffect } from "react";
import styled from "styled-components";
import PrivacyPolicyContent from "../components/PagesComponents/PrivacyPolicyContent";
import { Helmet } from "react-helmet";
const Container = styled.div`
`;
const PrivacyPolicy = () => {
  useEffect(()=>{window.scrollTo({ top: 0, left: 0, behavior: "smooth" });},[])
  return (
    <Container>
      <Helmet>
        <title>Privacy Policy & Cookies | Star Catering UK Supplies</title>
        <meta name="description" content="Privacy Policy & Cookies | Star Catering UK Supplies" />
        <meta name="robots" content="index, follow"/>
        <link rel="canonical" href={document.URL}/>
      </Helmet>
      <PrivacyPolicyContent />
    </Container>
  );
};

export default PrivacyPolicy;
