import styled from "styled-components";

const FeaturesImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  left: 0px;
  right: 0px;
  @media only screen and (max-width: 768px) {
    flex-direction: row;
    flex-wrap:wrap;
  }
`;
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  opacity: 0.75;
  margin: 1rem 2rem;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  padding-bottom: 1rem;
  & span {
    visibility: hidden;
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 100%;
    letter-spacing: 0.0015em;
    color: #222222;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.8rem;
    
  }
  }
  &:hover {
    opacity: 1;
    & span {
      visibility: visible;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 40%;
    margin: 1rem auto;
    
  }
`;
const EachFeatureImage = styled.img`
  width: 60%;
  height: 150px;
  object-fit: contain;
  @media only screen and (max-width: 768px) {
    height: 100px;
    
  }
`;

function FeaturesImageContent() {
  return (
      <FeaturesImage>
        <EachFeature>
          <EachFeatureImage src="/images/shipping.png"></EachFeatureImage>
          <span>Fast Shipping</span>
        </EachFeature>

        <EachFeature>
          <EachFeatureImage src="/images/contact.png"></EachFeatureImage>
          <span>Customer Service</span>
        </EachFeature>

        <EachFeature>
          <EachFeatureImage src="/images/payment.png"></EachFeatureImage>
          <span>Easy&Safe Payment</span>
        </EachFeature>

        <EachFeature>
          <EachFeatureImage src="/images/original.png"></EachFeatureImage>
          <span>100% Original</span>
        </EachFeature>
      </FeaturesImage>
  );
}

export default FeaturesImageContent;
