import React, { useEffect } from "react";
import styled from "styled-components";
import TermsConditionsContent from "../components/PagesComponents/TermsConditionsContent";
import { Helmet } from "react-helmet";
const Container = styled.div`
`;
const TermsConditions = () => {
  useEffect(()=>{window.scrollTo({ top: 0, left: 0, behavior: "smooth" });},[])
  return (
    <Container>
      <Helmet>
        <title>Terms and Conditions | Star Catering UK Supplies</title>
        <meta name="description" content="Terms and Conditions | Star Catering UK Supplies" />
        <meta name="robots" content="index, follow"/>
        <link rel="canonical" href={document.URL}/>
      </Helmet>
      <TermsConditionsContent />
    </Container>
  );
};

export default TermsConditions;
