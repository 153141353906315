import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {formatUrlCategoryString}  from "../../utilities/helpers"
import * as categoryActions from "../../redux/actions/categoryActions";
import styled from "styled-components";
const ListItem = styled.div`
  list-style:none ;
  cursor: pointer;
  min-width: 20rem;
`;
function MenuBarCategoriesContent(props) {
  const navigate = useNavigate()
  useEffect(() => {
    props.actions.getCategories();
  }, []);
  const _changeCategory=(category)=>{    
    const descLabel = formatUrlCategoryString(category.description);
    //navigate(`/product-categories/${descLabel}-${category.code.toLowerCase()}`);
    navigate(`/product-categories/${formatUrlCategoryString(category.code).toLowerCase()}`);
  }

  return (
    <div >
        {props.categories.filter(cat=>cat.indentation==="0").map((cat) => {
          return (
            <ListItem onClick={()=>_changeCategory(cat)} key={cat.code}>
              <a>{cat.description}</a>
            </ListItem>
          );
        })}
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCategories: bindActionCreators(
        categoryActions.getCategories,
        dispatch
      ),
    },
  };
}

function mapStateToProps(state) {
  return {
    categories: state.categoryListReducer,
  };
}
export default connect(mapStateToProps,mapDispatchToProps)(MenuBarCategoriesContent) ;
