/* eslint-disable jsx-a11y/no-distracting-elements */
import styled from "styled-components";
import { AddLocation, Call, Instagram,Facebook,Mail } from "@material-ui/icons";
import { colors } from "../../res/values/values";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex:1 ;
  //width: 100%;
  background-color: ${colors.whiteColor};
  color: ${colors.primaryColor};
  font-size: 14px;
  font-weight: 400;
  padding: 5px; 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  position: relative;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
`;
const Row = styled.div`
  font-size: 1rem;
  //width: 99%;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 2px;
  display: flex;
  flex-direction:row ;
  flex: 1;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const RowElemet = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
    margin-bottom: 0.5rem;
  }
  
`;
const RowElement = styled.div`
  margin-left: 0.8rem;
  display: flex;
  justify-content: space-between;
`;
const Divider = styled.div`
  border-left: 2px solid white;
  opacity: 0.6;
  margin-left: 5px;
`

function Announcement() {
  const navigate = useNavigate()
  return (
    <Container>
      <Row>
        <RowElemet>
        Delivering value, the caterer's essential ingredient.
        </RowElemet>
        {
          /*
            <RowElemet>
            <RowElement onClick={(_) => navigate("/route=about")}>
              <p style={{marginBottom:"0px"}}>About</p>
              <Divider/>
            </RowElement>
            <RowElement  onClick={(_) => navigate("/route=contact")}>
              <p style={{marginBottom:"0px"}}>Contact</p>
              <Divider/>
            </RowElement>
            </RowElemet>
          */
        }
        
      </Row>
      
    </Container>
  );
}
export default Announcement;
