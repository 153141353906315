import styled from "styled-components";
import { useState } from "react";
import { colors } from "../../res/values/values";
//#region styles
const Accordion = styled.div``;
const MainItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.1em;
  letter-spacing: 1.5px;
  font-weight: 600;
  cursor: pointer;
  background-color: white;
  color: ${colors.darkcolor};
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border-width: 0px 0px 1px 0px;
  border-color: lightgray;
  border-style: solid;
  &:hover {
    background-color: #f2f2f2;
  }
`;
const SubItem = styled.div`
  color: #231f20;
  font-family: "myriad-pro", Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-top: .6rem;
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
`;
//#endregion
function FaqsAccordionMenu(props) {
  const [isActive, setIsActive] = useState(false);
  return (
    <Accordion>
      <MainItem onClick={() => setIsActive(!isActive)}>
        <div>{props.title}</div>
        <div>{isActive ? "-" : "+"}</div>
      </MainItem>
      {isActive &&
        props.values.map((value, index) => {
          return <SubItem key={index}>{value}</SubItem>;
        })}
    </Accordion>
  );
}

export default FaqsAccordionMenu;
