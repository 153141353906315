/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components";
import AccordionMenu from "../MenuComponents/AccordionMenu";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import ImageSlider from "./ImageSlider";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";
import { colors } from "../../res/values/values";
import { useNavigate } from "react-router-dom";
import { formatUrlCategoryString } from "../../utilities/helpers";
const SideBar = styled.div`
  //width: 98%;
  @media only screen and (min-width: 600px) {
    width: 18rem;
    margin: 1rem 0.5rem;
  }
`;
const Row = styled.div`
  //width: 90%;
  border: 1px solid lightgray;
  padding: 5px;
  //margin: 10px;
  cursor: pointer;
  @media only screen and (min-width: 767px) {
  }
`;
const BreadCrumbText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: 500;
  border-bottom: 1px solid lightgray;
  color: ${colors.primaryColor};
  padding: 10px;
  overflow-wrap: anywhere;
  @media only screen and (min-width: 768px) {
  }
`;
const ClearFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.primaryColor};
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
  }
`;
function LeftSide(props) {
  const { width } = useWindowWidthAndHeight();
  const [accordionData, setAccordionData] = useState([]);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    let l_accordionData = [];
    if(props.selectedProductIdCategory){
      l_accordionData.push({
        parentid: props.selectedProductIdCategory.code,
        title: props.selectedProductIdCategory.description,
        type: "SubProductGroup",
        content: props.categories.filter(
          (cat) => cat.parentcode.toUpperCase() === props.selectedProductIdCategory.code.toUpperCase()
        ),
      });
      /*
      l_accordionData.push({
        title: "BRANDS",
        type: "Brands",
        content: props.manufacturers,
      });
      */
      setAccordionData(l_accordionData);
    }else if(props.selectedCategory){
      l_accordionData.push({
        parentid: props.selectedCategory.code,
        title: props.selectedCategory.description,
        type: "ProductGroup",
        content: props.categories.filter(
          (cat) => cat.parentcode.toUpperCase() === props.selectedCategory.code.toUpperCase()
        ),
      });
      /*
      l_accordionData.push({
        title: "BRANDS",
        type: "Brands",
        content: props.manufacturers,
      });
      */
      setAccordionData(l_accordionData);
    }else {
      l_accordionData.push({
        categoryid: props.categoryid,
        productid: props.productid,
        title: "CATEGORIES",
        type: "Category",
        content: props.categories.filter(
          (cat) => cat.indentation === "0"
        ),
      });
      /*
      l_accordionData.push({
        categoryid: props.categoryid,
        productid: props.productid,
        title: "BRANDS",
        type: "Brands",
        content: props.manufacturers,
      });
      */
      setAccordionData(l_accordionData);
    }
    props.filter ||
    props.selectedCategory ||
    props.selectedProductIdCategory ||
    props.brand ||
    props.country || props.selectedSubCategory
      ? setVisibleFilter(true)
      : setVisibleFilter(false);
  }, [props.filter,props.country,props.brand,props.selectedCategory,props.selectedProductIdCategory,props.selectedSubCategory]);

  const closeFilter = (type) => {
    
    if (type === "Category"){
      if(props.filter){
        navigate(`/catalogsearch/result/?${props.filter && `q=${props.filter}`}${props.brand ? `&brand=${props.brand}`:''}`)
      }
      else{
        if(!props.brand)
          navigate(`/product-categories/`);
        else{
          navigate(`/catalogsearch/result/?${props.brand ? `&brand=${props.brand}`:''}`)
        }
      }
        
    }
    else if (type === "ProductGroup"){
      if(!props.filter){
        if(!props.brand){
          navigate(`/product-categories/${props.selectedCategory.code.toLowerCase()}`);
        }else{
          navigate(`/catalogsearch/result/?cat=${props.selectedCategory.code.toLowerCase()}${props.brand ? `&brand=${props.brand}`:''}`)
        }
      }else{
        navigate(`/catalogsearch/result/?cat=${props.selectedCategory.code.toLowerCase()}${props.filter && `&q=${props.filter}`}${props.brand ? `&brand=${props.brand}`:''}`)
      }
    }
    else if (type === "SubProductGroup"){
      if(!props.filter){
        if(!props.brand){
          navigate(`/product-categories/${props.selectedCategory.code.toLowerCase()}/${props.selectedProductIdCategory.code.toLowerCase()}`);
        }else{
          navigate(`/catalogsearch/result/?cat=${props.selectedCategory.code.toLowerCase()}${props.selectedProductIdCategory ? `&subcat=${props.selectedProductIdCategory.code}`:''}${props.brand ? `&brand=${props.brand}`:''}`)
        }
      }else{
        navigate(`/catalogsearch/result/?cat=${props.selectedCategory.code.toLowerCase()}${props.selectedProductIdCategory ? `&subcat=${props.selectedProductIdCategory.code}`:''}${props.filter && `&q=${props.filter}`}${props.brand ? `&brand=${props.brand}`:''}`)
      }
    }
    else if (type === "Brand"){
      if(!props.filter){
        navigate(`/product-categories${props.selectedCategory ? `/${props.selectedCategory.code.toLowerCase()}`:''}${props.selectedProductIdCategory ? `/${props.selectedProductIdCategory.code.toLowerCase()}`:''}${props.selectedSubCategory ? `/${props.selectedSubCategory.code.toLowerCase()}`:''}`);
      }else{
        navigate(`/catalogsearch/result/?${props.selectedCategory.code.toLowerCase() ? `cat=${props.selectedCategory.code.toLowerCase()}`:''}${props.selectedProductIdCategory ? `&subcat=${props.selectedProductIdCategory.code}`:''}${props.selectedSubCategory ? `&b_subcat=${props.selectedSubCategory.code}`:''}${props.filter && `&q=${props.filter}`}`)
      }
    }
    else
      navigate(`/product-categories/`);
  };

  return (
    <SideBar>
      {visibleFilter && (
        <Row>
          {props.selectedCategory && (
            <BreadCrumbText>
              {" "}
              <a>{props.selectedCategory.description} </a>{" "}
              <a onClick={() => closeFilter("Category")}>X</a>{" "}
            </BreadCrumbText>
          )}
          {props.selectedProductIdCategory && (
            <BreadCrumbText>
              {" "}
              <a>{props.selectedProductIdCategory.description} </a>{" "}
              <a onClick={() => closeFilter("ProductGroup")}>X</a>{" "}
            </BreadCrumbText>
          )}
          {props.selectedSubCategory && (
            <BreadCrumbText>
              {" "}
              <a>{props.selectedSubCategory.description} </a>{" "}
              <a onClick={() => closeFilter("SubProductGroup")}>X</a>{" "}
            </BreadCrumbText>
          )}
          {props.brand && (
            <BreadCrumbText>
              {" "}
              <a>{props.brand} </a>{" "}
              <a onClick={() => closeFilter("Brand")}>X</a>{" "}
            </BreadCrumbText>
          )}
          {props.filter && (
            <BreadCrumbText>
              {" "}
              <a> - {props.filter} - </a>{" "}
              <a onClick={() => closeFilter("Filter")}>X</a>
            </BreadCrumbText>
          )}
          {props.country && (
            <BreadCrumbText>
              {" "}
              <a>{props.country} </a>{" "}
              <a onClick={() => closeFilter("Country")}>X</a>{" "}
            </BreadCrumbText>
          )}
          <ClearFilter
            onClick={() => {
              navigate(`/product-categories/`);
            }}
          >
            Clear Filter
          </ClearFilter>
        </Row>
      )}

      {accordionData.map((item) => {
        if (item.type !== "ProductGroup" && item.content.length > 0)
          return <AccordionMenu key={item.title} item={item} filter={props.filter} brand={props.brand} selectedCategory={props.selectedCategory} selectedProductIdCategory={props.selectedProductIdCategory} selectedSubCategory={props.selectedSubCategory}/>;
        if (item.type === "ProductGroup")
          return <AccordionMenu key={item.title} item={item} filter={props.filter} brand={props.brand} selectedCategory={props.selectedCategory} selectedProductIdCategory={props.selectedProductIdCategory} selectedSubCategory={props.selectedSubCategory}/>;
      })}
      {width > 600 && <ImageSlider type={"left"} />}
    </SideBar>
  );
}
function mapStateToProps(state) {
  return {
    categories: state.categoryListReducer,
    manufacturers: state.manufacturerReducer,
    countryList: state.countryReducer,
  };
}
export default connect(mapStateToProps)(LeftSide);
