import { Checkbox } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { colors, API_URL } from "../../res/values/values";
import OrcaModal from "../GlobalComponents/OrcaModal";
import useForm from "../../utilities/hooks/useForm";
import { registerValidateInfo } from "../../utilities/helpers";
import { Call } from "@material-ui/icons";

//#region Styles
const Container = styled.div`
  min-height: 40vw;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 20px;
`;
const Wrapper1 = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  padding: 20px;
`;
const Wrapper = styled.div`
  padding: 20px;
  background-color: white;
`;
const Title = styled.div`
  color: ${colors.primaryColor};
  font-size: 24px;
  font-weight: 500;
`;
const Label = styled.div`
  width: 100%;
  color: #333;
  font-size: 18px;
  font-weight: 300;
  margin-top: 20px;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
`;
const Link = styled.a`
  color: ${colors.primaryColor};
  text-decoration: none;
  cursor: pointer;
`;
const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;
const InputElement = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 90%;
  margin: 10px 10px;
  @media only screen and (min-width: 900px) {
    min-width: 40%;
  }
`;
const CallDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 10px;
`;
const Input = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px;
  border-color: lightgray;
  border-style: solid;
  //max-height: 1.1rem;
  &:focus {
    outline: none !important;
    border-color: ${colors.primaryColor};
  }
`;
const Agreement = styled.span`
  width: 100%;
  font-size: 14px;
  margin: 20px 0;
`;
const Button = styled.input`
  border: none;
  padding: 15px 20px;
  background-color: ${colors.primaryColor};
  color: white;
  margin-bottom: 10px;
  cursor: pointer;
`;
const Error = styled.div`
  text-align: center;
  padding: 5px;
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  font-size: 16px;
  font-weight: 300;
  margin: 1rem;
`;
const ValidateError = styled.p`
  color: ${colors.errorcolor};
  margin-left: 10px;
  font-size: 0.8rem;
`;

const SuccessContainer = styled.div`
  min-height: 40vw;
  display: flex;
  justify-content: flex-start;
`;
const SuccessWrapper = styled.div`
  padding: 20px;
  width: 60%;
  background-color: white;
`;
const SuccessLabel = styled.h1`
  color: ${colors.primaryColor};
  width: 100%;
  font-size: 24px;
  margin: 20px 0;
`;
const SuccessAgreement = styled.span`
  width: 100%;
  font-size: 16px;
  margin: 20px 0;
`;
const SuccessLink = styled.a`
  color: ${colors.primaryColor};
  text-decoration: none;
`;
const TermButtonActive = styled.div`
  display: flex;
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  background-color: ${colors.primaryColor};
  border-color: #dddddd #dddddd #b3b3b3 #b7b7b7;
  color: #fff;
  border-radius: 2px;
  padding: 8px;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.darkcolor};
  }
`;
const TermTitle = styled.div`
  color: ${colors.primaryColor};
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 1rem;
`;
const TermTextBlock = styled.div`
  height: 15rem;
  overflow-y: scroll;
  display: block;
  flex-direction: column;
`;
//#endregion
function RegisterForm(props) {
  const navigate = useNavigate();
  const [registerError, setRegisterError] = useState("");
  const [checkTerms, setCheckTerms] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const userRegisterFunc = async (values) => {
    if (checkTerms) {
      let newUser = {
        companyname: values.companyname,
        address: values.address,
        address2: values.address2,
        postcode: values.postcode,
        city: values.city,
        country: values.country,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email.toLowerCase(),
        telephone: values.telephone,
        password: values.password,
      };
      console.log(newUser);
      let url = `${process.env.REACT_APP_API}/auth/register`;
      fetch(url, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(newUser),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setRegisterError("");
            navigate("/account/register?success=true");
            return true;
          } else {
            setRegisterError(res.message);
            return false;
          }
        });
    } else {
      setRegisterError("Warning: You must agree to the Terms & Conditions!");
      return false;
    }
  };
  const { handleChange, handleSubmit, values, errors } = useForm(
    userRegisterFunc,
    registerValidateInfo
  );
  if (!props.settings.registeruser)
    return (
      <Wrapper1>
        <h2> Registration is not available yet.</h2>
        <h2> Please come back later or call us for more information</h2>
        <CallDiv>
          <Call style={{ marginRight: "5px" }} />
          <h2>+441302 325890</h2>
        </CallDiv>
      </Wrapper1>
    );
  if (props.success) {
    return (
      <SuccessContainer>
        <SuccessWrapper>
          <SuccessLabel>Your Account Has Been Created!</SuccessLabel>
          <SuccessAgreement>
            Thank you for registering with Star Catering Supplies Ltd!
          </SuccessAgreement>
          <SuccessAgreement>
            You will be notified by e-mail once your account has been activated
            by the store owner.
          </SuccessAgreement>
          <SuccessAgreement>
            If you have ANY questions about the operation of this online shop,
            please{" "}
            <SuccessLink
              onClick={(_) => {
                navigate("/");
              }}
            >
              contact the store owner
            </SuccessLink>
            .
          </SuccessAgreement>
        </SuccessWrapper>
      </SuccessContainer>
    );
  } else {
    return (
      <div>
        <OrcaModal isOpen={open} onClose={handleClose}>
          <TermTextBlock>
            <TermTitle>Terms & Conditions</TermTitle>
            <p>
              “We” “Us” “Our” means Star Catering Supplies Limited, a company
              registered in England. Company number 02839246, whose registered
              office is at Unit 1-8, Centenary Estate Jeffreys Rd Enfield London
              EN3 7UD.
            </p>
            <p>
              <strong>1. Orders</strong>
            </p>
            <p>
              By placing an order with us via our telesales department, the
              customer is offering to contract with us on the basis of these
              terms and conditions.
            </p>
            <p>
              The parties are bound by the contract only when we accept to
              process the order or deliver the products to the customer. The
              contract is subject to availability of the stock and market
              conditions.
            </p>
            <p>
              We may limit the quantities of goods supplied to any one customer.
            </p>
            <p>
              In the event of non-availability of any goods you may order, we
              may offer a reasonable substitute. This may affect the price you
              pay. You may reject any substitute item and we will refund the
              amount you have been charged for that substitute.
            </p>
            <p>
              Customers last orders for telesales is 5:30pm; the orders are
              processed for the next available delivery date.
            </p>
            <p>
              Calls made to telesales may be recorded for training purposes and
              we reserve the right to refuse the acceptance of an order and/or
              to terminate any customers trading account without explanation or
              notice.
            </p>
            <p>
              <strong>2. Minimum order</strong>
            </p>
            <p>
              Our minimum order value for delivery inside the M25 is £75 net
              value. For deliveries outside the M25 the minimum delivery charge
              varies according to the area.
            </p>
            <p>
              We reserve the right not to deliver an order which is valued at
              less than the latest minimum order value.
            </p>
            <p>
              <strong>3. Deliveries</strong>
            </p>
            <p>
              Deliveries of products to the customer shall be in accordance with
              our delivery schedule. We will not always guarantee delivery in
              accordance to the customers requested timing schedule.
            </p>
            <p>
              We shall not be liable for any loss or damage whatsoever arising
              as a result of failure to deliver products by a particular date or
              arising as a result of any causes beyond our control.
            </p>
            <p>
              The customer shall be present at the premises to take and sign off
              the delivery of the products. Deliveries will be made to the
              address specified by the customer when the account was created.
            </p>
            <p>
              Health and Safety standards must be met at all premises receiving
              delivery from us. Failure to do so may result in us being unable
              to place the delivery in the required area.
            </p>
            <p>
              We reserve the right to restrict deliveries in certain areas, and
              this includes the right to eliminate certain areas from our
              delivery schedule altogether.
            </p>
            <p>
              If the customer fails to take the delivery or fails to give us
              adequate delivery instructions of the time stated for delivery
              then without prejudice to any other right or remedy available to
              us, we may do one or more of the following:
            </p>
            <ul>
              <li>
                <strong>3.1 -</strong> Charge the customer the cost of carriage
                of the refused delivery both to and from the premises of the
                customer in addition to our administration charges. A charge of
                £20.00 or 10% of the delivery value, which ever is greater, will
                be made.
              </li>
              <li>
                <strong>3.2 -</strong> Charge full cost of price for perishable
                products.
              </li>
              <li>
                <strong>3.3 -</strong> Charge the customer a rescheduling
                delivery charge.
              </li>
            </ul>
            <p>
              <strong>4. Shortages</strong>
            </p>
            <p>
              The number of packages delivered must be checked in the presence
              of the driver. Customers will sign off the delivery note
              highlighting the shortages countersigned by our driver. Claims for
              shortages should be made at the time of delivery.
            </p>
            <p>
              <strong>5. Risk and ownership</strong>
            </p>
            <p>
              All risks in the goods pass to the customer on delivery. Ownership
              of goods delivered to customers shall remain with us until payment
              of the invoiced cost has been received in full.
            </p>
            <p>
              <strong>6. Warranties</strong>
            </p>
            <p>
              We warrant that all food products and non-food products shall
              comply with all U.K and EU legislations.
            </p>
            <p>
              <strong>7. Price</strong>
            </p>
            <p>
              Contract price is invoiced price. Any offers are subject to
              availability and the prices quoted or shown on our literature are
              excluded of V.A.T.
            </p>
            <p>
              Limited quantities on selected products may apply. We reserve the
              right to amend these offers, prices and pack sizes at any time.
              Prices of some of the products may vary at Brighton depot. Please
              inquire. Not all products are available at Brighton depot. Every
              effort has been made to ensure the accuracy of these prices and
              special offers, however discrepancies may occur.
            </p>
            <p>
              V.A.T will be charged at the appropriate rate on goods where
              applicable.
            </p>
            <p>
              <strong>8. Payments</strong>
            </p>
            <p>
              Customers must settle all accounts in accordance with all agreed
              terms. In the event of a customer account being in arrears, we
              reserve the right to charge statutory interest on overdue balances
              for the period from the date on which the payment was due.
            </p>
            <p>
              The customer may choose to settle their account with debit or
              credit card. Debit and credit cards accepted by us are those
              listed in our promotional magazine or website. Authority for
              payment will be requested from your card issuer at the time of
              payment and your card will be charged accordingly.
            </p>
            <p>
              We reserve the right to terminate our agreement with you if we are
              refused authority for payment or reasonably believe that payment
              will be refused at any stage. In the event of any cheques,
              standing orders or direct debits due from a customer to us being
              dishonoured, a charge of £30 will be made on the customers account
              to cover the bank and administrative cost. For dishonoured
              cheques, customers terms will change to “cash on delivery” and the
              account will be placed on hold until cleared funds are received in
              full to settle the outstanding balance of the account.
            </p>
            <p>
              For credit account customers, any non-payment or late payment will
              be considered as a breach of the agreed terms, will render credit
              terms null and void. Customer account will be put on hold.
            </p>
            <p>
              We have the right to start legal proceedings to recover any
              outstanding amount without any prior notice and the right to
              exchange information with credit agencies.
            </p>
            <p>
              A maximum cash payment by coins allowed by any one customer is £60
              in £1 or £2 coins only.
            </p>
            <p>
              <strong>9. Account Terms</strong>
            </p>
            <p>
              Initially all new accounts will be set as C.O.D (Cash on Delivery)
              for a minimum period of three months. We thereafter may apply for
              a credit account pending decision and subject to references and
              cross references with credit agencies. Customers will continue
              with current terms.
            </p>
            <p>
              All credit account invoices of one calendar month are due for
              payment on the 15th day of the following month, (e.g. All January
              invoices are due on the 15th of February).
            </p>
            <p>
              <strong>10. Returns</strong>
            </p>
            <p>
              We will only credit the customer if the wrong product was
              delivered and returned on the same day with the same delivery
              driver in original condition. Any other returns, our drivers are
              not authorised to accept goods to return without the appropriate
              documentation.
            </p>
            <p>
              <strong>11. General Provisions</strong>
            </p>
            <p>
              The contract and any claims arising in connection with it shall be
              governed by English law and any dispute between us will be
              resolved exclusively in the English courts.
            </p>
            <p>
              <strong>12. Disclaimer</strong>
            </p>
            <p>
              Please note: All offers are subject to availability. Limited
              quantities on selected products may apply. Prices shown are
              exclusive of VAT at 20% where applicable. We reserve the right to
              amend these offers, prices and pack sizes at any time. Every
              effort has been made to ensure the accuracy of these special
              offers, however discrepancies may occur. Our standard terms and
              conditions apply, which can be viewed on our website or copies are
              available on request. All images are for guidance purposes only.
              Design, including text and photography are the copyright of Star
              Catering Supplies Ltd and associated individuals &amp; companies.
              E &amp; OE. ©2020
            </p>
          </TermTextBlock>

          <TermButtonActive onClick={() => handleClose()}>
            Close
          </TermButtonActive>
        </OrcaModal>
        {registerError !== "" ? <Error>{registerError}</Error> : <div></div>}
        <Container>
          <Wrapper>
            <Title>Register Account</Title>
            <Agreement>
              If you already have an account with us, please login at the{" "}
              <Link href="/login">login page.</Link>
            </Agreement>
            <Label>Your Personal Details</Label>
            <Form onSubmit={handleSubmit}>
              <InputElement>
                <Input
                  name="companyname"
                  placeholder="* Company Name"
                  onChange={handleChange}
                  value={values.companyname}
                />
                {errors.companyname && (
                  <ValidateError>{errors.companyname}</ValidateError>
                )}
              </InputElement>
              <InputElement>
                <Input
                  name="address"
                  placeholder="* Address"
                  onChange={handleChange}
                  value={values.address}
                />
                {errors.address && (
                  <ValidateError>{errors.address}</ValidateError>
                )}
              </InputElement>
              <InputElement>
                <Input
                  name="address2"
                  placeholder="Address 2"
                  onChange={handleChange}
                  value={values.address2}
                />
                {errors.address2 && (
                  <ValidateError>{errors.address2}</ValidateError>
                )}
              </InputElement>
              <InputElement>
                <Input
                  name="postcode"
                  placeholder="* Post Code"
                  onChange={handleChange}
                  value={values.postcode}
                />
                {errors.postcode && (
                  <ValidateError>{errors.postcode}</ValidateError>
                )}
              </InputElement>

              <InputElement>
                <Input
                  name="city"
                  placeholder="* City"
                  onChange={handleChange}
                  value={values.city}
                />
                {errors.city && <ValidateError>{errors.city}</ValidateError>}
              </InputElement>
              <InputElement>
                <Input
                  name="country"
                  placeholder="* County"
                  onChange={handleChange}
                  value={values.country}
                />
                {errors.country && (
                  <ValidateError>{errors.country}</ValidateError>
                )}
              </InputElement>
              <InputElement>
                <Input
                  name="firstname"
                  placeholder="* First Name"
                  onChange={handleChange}
                  value={values.firstname}
                />
                {errors.firstname && (
                  <ValidateError>{errors.firstname}</ValidateError>
                )}
              </InputElement>
              <InputElement>
                <Input
                  name="lastname"
                  placeholder="* Last Name"
                  onChange={handleChange}
                  value={values.lastname}
                />
                {errors.lastname && (
                  <ValidateError>{errors.lastname}</ValidateError>
                )}
              </InputElement>
              <InputElement>
                <Input
                  name="email"
                  type="email"
                  placeholder="* E-mail"
                  onChange={handleChange}
                  value={values.email}
                />
                {errors.email && <ValidateError>{errors.email}</ValidateError>}
              </InputElement>
              <InputElement>
                <Input
                  name="telephone"
                  placeholder="* Telephone"
                  onChange={handleChange}
                  value={values.telephone}
                />
                {errors.telephone && (
                  <ValidateError>{errors.telephone}</ValidateError>
                )}
              </InputElement>

              <Label>Your Password</Label>
              <InputElement>
                <Input
                  name="password"
                  type="password"
                  placeholder="* Password"
                  onChange={handleChange}
                  value={values.password}
                />
                {errors.password && (
                  <ValidateError>{errors.password}</ValidateError>
                )}
              </InputElement>
              <InputElement>
                <Input
                  name="passwordConfirm"
                  type="password"
                  placeholder="* Password Confirm"
                  onChange={handleChange}
                  value={values.passwordConfirm}
                />
                {errors.passwordConfirm && (
                  <ValidateError>{errors.passwordConfirm}</ValidateError>
                )}
              </InputElement>

              <Agreement>
                I have read and agree to the{" "}
                <Link onClick={() => handleOpen()}>Terms & Conditions</Link>{" "}
                <Checkbox
                  sx={{
                    color: colors.primaryColor,
                    "&.Mui-checked": {
                      color: colors.primaryColor,
                    },
                  }}
                  onClick={() => {
                    setCheckTerms(!checkTerms);
                  }}
                  value={checkTerms}
                />
              </Agreement>

              <Button type="submit" value="Create"></Button>
            </Form>
          </Wrapper>
        </Container>
      </div>
    );
  }
}

export default RegisterForm;
