import styled from "styled-components";

//#region Styles

const Container = styled.div`
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  padding: 10px;
`;
const PageTitle = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "myriad-pro", Arial, sans-serif;
  & p {
    color: #000;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400;
    margin: 0;
    padding-bottom: 10px;
    padding-top: 15px;
  }

  & h1 {
    margin-top: 1rem;
    border-bottom: none;
    font-size: 30px;
    font-weight: 600;
  }
`;
const DivStd = styled.div`
  display: block;
  & img {
    margin: 25px 0 15px;
    max-width: 100%;
  }
`;
const ExportServicesText = styled.div`
  margin: 0 0 30px;
  & p {
    color: #000;
    font-size: 18px;
    font-style: normal !important;
    font-weight: 500;
    margin: 0;
    padding-bottom: 10px;
    padding-top: 15px;
    @media only screen and (max-width: 639px) {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
    }
    @media only screen and (max-width: 379px) {
        font-size: 18px;
    }
    & a {
        text-decoration: none;
        color: #D5393F;
    }
  }
`;
const ExportServicesTitle = styled.p`
    font-size: 26px;
    font-style: normal !important;
`
//#endregion
function QualityAssuranceContent() {
  return (
    <Container>
      <PageTitle>
        <h1>Quality Assurance</h1>
        
      </PageTitle>
      <DivStd>
        <ExportServicesText>
            <h4>Certifications:</h4>
            <p>Star Catering Supplies Ltd achieved Quality (ISO 9001), Environmental (ISO 14001), Health &amp; Safety</p>
            <strong>(OHSAS 18001) and HACCP Management Systems certification with flying colours.&nbsp;</strong>
        </ExportServicesText>
        <img
          src="https://www.star-catering.co.uk/media/wysiwyg/quality/ISO-web-banner-21-04-2020-ISO-INFO-PAGE_0.jpg"
          alt=""
        />
        <ExportServicesText>
            <ExportServicesTitle>ISO 9001:2015</ExportServicesTitle>
            <p>Star Catering successfully implemented an Integrated Management System that includes this International ISO Standard. This quality management system standard certification confirms our ability to provide products that consistently meet customer and legal requirements. We embedded continuous improvement at the heart of our system and by doing so enhancing customer satisfaction.</p>
        </ExportServicesText>

        <ExportServicesText>
            <ExportServicesTitle>ISO 14001:2015</ExportServicesTitle>
            <p>In essence Star Catering developed this environmental management system to manage its business activities in an environmentally friendly manner. This international standard requires us to determine our impact on the environment. Our system is consistent with our environmental policy and objectives and provide us sound environmental performance and continuous improvements in environmental matters.</p>
        </ExportServicesText>

        <ExportServicesText>
            <ExportServicesTitle>OHSAS 18001:2007</ExportServicesTitle>
            <p>We developed our Occupational Health & Safety management system with built in measures that will assist us to control health and safety risks to our employees, customers and visitors. Our aim is to achieve and maintain Incident & Injury Free (IIF) work place consistent with our OH&S policy and objectives</p>
        </ExportServicesText>

        <ExportServicesText>
            <ExportServicesTitle>HACCP</ExportServicesTitle>
            <p>The implemented HACCP or the Hazard Analysis Critical Control Point system is a process control system that help us identify where hazards might occur in the food chain supply, storage & distribution so we can put into place stringent actions and procedures to remove & to prevent the hazards from occurring. We wanted to assure our customers and be confident with our products.</p>
        </ExportServicesText>
        <hr style={{borderTop:"dotted 1px",marginBottom:"40px"}}/>

        <ExportServicesText>
            <ExportServicesTitle>Why did we do it?</ExportServicesTitle>
            <p>It is important to provide reliable quality service, be environmentally friendly in all our operations, drive efficiency, provide a safe environment for our employees & customers alike and most importantly be able to handle food products safely at all times.</p>
        </ExportServicesText>

        <ExportServicesText>
            <ExportServicesTitle>We will always put our customers first!</ExportServicesTitle>
            <p>By implementing all these Best Practice Management Systems which drives efficiency on all aspects of our business creates savings and by achieving savings enables us to offer goods and services that has unquestionable ‘Value for Money’ offering to our customers.</p>
        </ExportServicesText>

      </DivStd>
    </Container>
  );
}

export default QualityAssuranceContent;
