import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import styled from "styled-components";
import { colors } from "../../res/values/values";
import  { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import en from "date-fns/locale/en-GB";
import { format } from "date-fns";
registerLocale("en", en);

//#region STYLES

const Container = styled.div`
  min-height: 10rem;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;
const Button = styled.div`
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 1px;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  background-color: ${colors.primaryColor};
  border-color: #dddddd #dddddd #b3b3b3 #b7b7b7;
  color: #fff;
  border-radius: 2px;
  margin: 2px;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.darkcolor};
  }
`;
const ButtonDatePricker = styled.div`
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  background-color: #dddddd;
  border-color: #b3b3b3 #b3b3b3 #b3b3b3 #b3b3b3;
  color:${colors.darkcolor};;
  border-radius: 2px;
  margin: 2px;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.lightcolor};
  }
`;
const Title = styled.span`
  font-size: 1rem;
  color: ${colors.primaryColor};
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  flex: 3;
`;
//#endregion

export default function PaymentMethodComponent(props) {

  const handleChange = async (event) => {
    await props.changePaymentMethod(event.target.value);
  };

  return (
    <Container>
      <FormControl>
        <Title>
            Please select the preferred payment method to use on this order.
        </Title>
        <RadioGroup
          aria-labelledby="radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={props.paymentMethod}
          onChange={handleChange}
        >

          
          <FormControlLabel
            value="Card"
            control={
              <Radio
                sx={{
                  color: colors.primaryColor,
                  "&.Mui-checked": {
                    color: colors.primaryColor,
                  },
                }}
              />
            }
            label={"Debit / Credit Card Payment"}
          />
        </RadioGroup>
      </FormControl>
      <ButtonContainer>
        <Button
          onClick={() => {
            props.changeOpenStatus(true);
          }}
        >
          Continue
        </Button>
      </ButtonContainer>
    </Container>
  );
}

/*
<FormControlLabel
            value="Cash"
            control={
              <Radio
                sx={{
                  color: colors.primaryColor,
                  "&.Mui-checked": {
                    color: colors.primaryColor,
                  },
                }}
              />
            }
            label= {props.deliveryMethod + " Cash Payment"}
          />
*/