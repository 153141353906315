import React from "react";
import styled from "styled-components";
import AboutContent from "../components/PagesComponents/AboutContent";
import useWindowWidthAndHeight from "../utilities/hooks/useWindowWidthAndHeight";
import { Helmet } from "react-helmet";
const Container = styled.div`
  //width: ${(props) => props.width}px;
`;
const About = () => {
  const { width } = useWindowWidthAndHeight();
  return (
    <Container width={width}>
      <Helmet>
        <title>About Us | Star Catering UK Supplies</title>
        <meta name="description" content="About Us | Star Catering UK Supplies" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={document.URL}/>
      </Helmet>
      <AboutContent />
    </Container>
  );
};

export default About;
