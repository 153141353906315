import { Divider } from "@material-ui/core";
import {Call, Email,Facebook,Twitter } from "@material-ui/icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { colors } from "../../res/values/values";
import { Instagram } from "@mui/icons-material";
const Container = styled.div`
  color: #202435;
  background-color:#e8e7e8;
  width: 100%;
`;
const Top = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction:column ;
  @media only screen and (min-width: 600px) {
    flex-direction: row;
  }

`;
const TopContent = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction:column ;
  @media only screen and (min-width: 600px) {
    flex-direction: row;
  }

`;
const CallInformation = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:row ;

`;
const TFCInformation = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:row ;

`;
const TFCContent = styled.div`
  & h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 3px;
    }
  & p {
    font-size: .6875rem;
    opacity: .5;
  }
`;
const CallIcon = styled.div`

`;
const CallPhone = styled.div`
  & h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 3px;
    }
  & p {
    font-size: .6875rem;
    opacity: .5;
  }
`;
const CompanyAppInformation = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:row ;

`;
const Information = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:row ;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
  & a {
    color: #808285;
    text-decoration-line: none;
    outline: medium none !important;
    @media only screen and (max-width: 600px) {
    margin-top: .5rem;
  }
    &:hover {
      text-decoration-line: underline;
  }
  }
  

`;
const CustomMenu = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction:column ;
  

  & a {
    color: #808285;
    text-decoration-line: none;
    outline: medium none !important;
    margin-bottom: .5rem;
    @media only screen and (max-width: 600px) {
  }
    &:hover {
      text-decoration-line: underline;
  }
  }
  

`;
const CompanyAppInformationLabel = styled.div`
  & h6 {
    font-size: .875rem;
    font-weight: 600;
    margin-bottom: 0;
    }
  & span {
    display: block;
    font-size: .75rem;
    opacity: .5;
  }
`;
const Img = styled.img`
    height: auto;
    max-width: 100%;
    margin-left: .5rem;
`;
const ImgTfc = styled.img`
    height: auto;
    max-width: 5rem;
    margin-left: .5rem;
`;

const ColumnList = styled.ul`
  max-width: 1080px;
  list-style-type: none;
  margin-left: 10px;
  margin-right:10px ;
  padding: 0;
  cursor: pointer;
  background-color: aliceblue;
`;
const ColumnListTitle = styled.li`
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: 1px;
`;
const ColumnListItem = styled.li`
  margin-bottom: 5px;
  letter-spacing: 1px;
  display: flex;
`;
const DividerRow = styled.div`
  border-left: 2px solid #202435;;
  opacity: 0.6;
  margin-left: 5px;
`
const Bottom = styled.div`
    padding:5px ;
    color: #202435;
  background-color:#e8e7e8;
    text-align: center;
   
  & p {
    margin: 8px 0;
    color: #202435;
    font-size: 0.8rem;
    & a {
      color: #202435;
      font-size: 0.8rem;
    }
  }
`;
function Footer() {
  const navigate = useNavigate();
  return (
    <Container>
      <Top>
        <TopContent>
          <CallInformation>
            <CallIcon>
              <Call style={{height:"30px", width:"30px",padding:"5px"}}/>
            </CallIcon>
            <CallPhone>
              <h2>020 8527 2666</h2>
              <p>Working 8.00 - 18.00</p>

            </CallPhone>
          </CallInformation>
        </TopContent>
        <TopContent>
          <CustomMenu>
            <a href={`${window.location.origin}/faqs`}>Help / FAQ's</a>
            <a href={`${window.location.origin}/quality-assurance`}>Quality Assurance</a>
            <a href={`${window.location.origin}/star-bulletin`}>News</a>
            <a href={`${window.location.origin}/export-services`}>Export Services</a>
          </CustomMenu>
        </TopContent>
        <TopContent>
          { /* APP BOLUMU */}
          <CompanyAppInformation>
            <a style={{color:colors.darkcolor}} href="https://www.facebook.com/StarCateringSuppliesLtd">
              <Facebook style={{height:"30px", width:"30px",padding:"5px"}}/>
            </a>
            <a style={{color:colors.darkcolor}} href="https://www.instagram.com/starcateringsuppliesltd/">
              <Instagram style={{height:"30px", width:"30px",padding:"5px"}}/>
            </a>
            <a style={{color:colors.darkcolor}} href="">
              <Email style={{height:"30px", width:"30px",padding:"5px"}}/>
            </a>
            <a style={{color:colors.darkcolor}} href="https://twitter.com/i/flow/login?redirect_after_login=%2FStarCateringLtd">
              <Twitter style={{height:"30px", width:"30px",padding:"5px"}}/>
            </a>
          </CompanyAppInformation>
        </TopContent>
  
      </Top>
      <Top>
        <TopContent>
          <TFCInformation>
            <TFCContent>
              <ImgTfc src="/images/TFC-Holding-Logo.png" alt="" />
              <p> Star Catering  is a subsidiary of TFC Holdings.</p>
            </TFCContent>
          </TFCInformation>
        </TopContent>
        <TopContent>
          <Information>
            <a href={`${window.location.origin}/terms-conditions`}>Terms &amp; Conditions.</a>
             <Divider orientation="vertical" style={{ height: "1.5rem",marginInline: "1rem" }} />
            <a href={`${window.location.origin}/privacy-policy-cookies`}>Privacy Policy &amp; Cookies</a>
            <Divider orientation="vertical" style={{ height: "1.5rem",marginInline: "1rem" }} />
            <a title="Modern Slavery Act" href="https://www.star-catering.co.uk/media/cmsblocks/pdfs/modernslavery.pdf" target="_blank">Modern Slavery Act Statement</a>
          </Information>
        </TopContent>
  
      </Top>
      <Divider style={{ marginInline: "2rem" }} />
      <Bottom>
      <p>Powered By <strong> <a href="http://www.orcabs.com">Orca Business Solutions Ltd.  -  </a></strong> Star Catering Supplies Ltd © 2023</p>
      </Bottom>
     
    </Container>
  );
}

export default Footer;


/* APP BOLUMU
<CompanyAppInformation>
            <CompanyAppInformationLabel>
              <h6>
              Download App on Mobile :
              </h6>
              <span>15% discount on your first purchase</span>
            </CompanyAppInformationLabel>
            <CompanyAppInformationLabel>
              <a href="https://play.google.com/store">
                <Img src="/images/google-play.png" alt="" />
              </a>
              <a href="https://www.apple.com/app-store/">
                <Img src="/images/app-store.png" alt="" />
              </a>
              
            </CompanyAppInformationLabel>
          </CompanyAppInformation>
*/