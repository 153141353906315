import React, { useEffect } from "react";
import styled from "styled-components";
import HomeContent from "../components/PagesComponents/HomeContent";
import ImageSlider from "../components/GlobalComponents/ImageSlider";
import {Helmet} from "react-helmet";
const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;
const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <Container>
      <Helmet>
        <title>Star Catering UK Supplies | Wholesale Food &amp; Catering Supplies, Cash &amp; Carry and Online Orders for Delivery, Collection, Export</title>
        <meta name="description" content="We sell wholesale food and catering supplies to consumers and businesses across the UK and for export. The best stock at the best prices for retailers, caterers and Restaurants. Register on our website to order with us today." />
        <link rel="canonical" href={document.URL}/>
      </Helmet>
      <ImageSlider type={"home"} />
      <HomeContent/>      
    </Container>
  );
};

export default Home;
