import React from "react";
import styled from "styled-components";
import BrandsContent from "../components/PagesComponents/BrandsContent";
import { Helmet } from "react-helmet";
const Container = styled.div`
  //width: ${(props) => props.width}px;
`;
const News = () => {
  return (
    <Container>
      <Helmet>
        <title>Brands | Star Catering UK Supplies</title>
        <meta
          name="description"
          content="Brands | Star Catering UK Supplies"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={document.URL}/>
      </Helmet>
      <BrandsContent />
    </Container>
  );
};

export default News;
