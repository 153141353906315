import styled from "styled-components";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";
import { Helmet } from "react-helmet";
import AccountMenu from "../AccoundComponents/AccountMenu";
import { colors } from "../../res/values/values";
import FaqsAccordionMenu from "../MenuComponents/FaqsAccordionMenu";
import { useState } from "react";

const Container = styled.div`
 
  max-width: 960px;
  margin: 0 auto;
  padding: 10px;
`;
const TwoColumn = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  @media only screen and (min-width: 600px) {
    flex-direction: row;
  }
`;
const LeftDiv = styled.div`
  @media only screen and (min-width: 600px) {
    width: 20rem;
  }
`;
const RightDiv = styled.div`
  width: 100%;
`;
const Element = styled.p`
  color: ${(props) => props.selected ? colors.errorcolor : colors.darkcolor};
  font-weight: 600;
`;
const List = styled.ul`
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 0;
  list-style: none;
  & li {
    display: list-item;
    text-align: -webkit-match-parent;
    margin-bottom: 5px;
    margin-top: 5px;
    padding:5px 5px 5px 0px ;
    cursor: pointer;
  }
`;
function FaqsContent(props) {
  const { height, width } = useWindowWidthAndHeight();
  
  const delivery = {
    values:[
        { title:'How do I book a delivery slot?', texts:['To book a delivery slot you must Login and start a new order, from here you can choose a date and time slot for delivery.','Click the 4 hour window you would like your delivery and you are ready to go! Orders can be placed up to 1 week before your delivery date.'] },
        { title:'What days do you deliver?', texts:['Deliveries are typically Monday to Sunday between 6:30am and 17:00pm.','If you are in an area that does offer alternative delivery times, this will be indicated to you when you book your delivery slot.','Delivery slots are 4 hour windows.'] },
        
        { title:'What is the minimum spend for delivery?', texts:['Our minimum order value for delivery inside the M25 is £100 net value. For deliveries outside the M25 is £200 net value.','Depending on your area, the minimum value will be indicated when you start a new order.','We reserve the right not to deliver an order which is valued at less than the latest minimum order value.'] },
        { title:'How much does delivery cost?', texts:['Delivery is free throughout London, M25 and the Home Counties*','*Terms & Conditions apply, minimum spend required.'] },
        { title:'What if I need to change my delivery date or time? ', texts:['You can change your own delivery date or time up to 5:30pm on the evening before your delivery day. You can do this by checking into your account and clicking onto My Account, selecting My Orders and click on "change slot" and change to a more suitable delivery slot. To confirm the change you need to click on "Confirm".','If you are changing the date or time on the same day then you must contact our Sales Team on 020 8527 2666*, as soon as possible. We will happily re-arrange to a time/date that is convenient for you','*Calls may be recorded for training purposes.'] },
        { title:'How do you deliver my shopping?', texts:['Deliveries are made to your door/shop in our temperature controlled vehicles by fully trained drivers.'] },
        { title:'Who can sign for my delivery?', texts:['All deliveries must be signed for by the customer, or a person nominated by the customer, who is over the age of 18.','If the order contains alcohol, proof of age may be requested, and Star Catering reserves the right to ask to see the ID of anyone that looks under the age of 25 and can refuse delivery to anybody that cannot prove they are over 18 years of age.','Proof of identity may also be requested upon delivery. Signature upon receipt of the goods will be proof of delivery.'] },
        { title:'What happens if I am not at home when you deliver?', texts:['Our Delivery driver will leave a card with the branch name and telephone number for you to contact and take your items back to the branch.','Whenever possible, we will try and arrange a re-delivery for you, but this is not guaranteed and is subject to availability. Alternatively you can contact our Sales Team on 020 8527 2666*','*Calls may be recorded for training purposes.'] },
        { title:'What do I do if I have missing or damaged items?', texts: ['Any missing or damaged items must be reported to our Sales Team, on 020 8527 2666*, Our phone lines are open Monday - Friday 9.00am - 17:30pm and Sunday 10.30am - 17:30pm.','*Calls may be recorded for training purposes.']},
    ]
  }
  const collection = {
    values:[
        { title:'How to book a collection slot?', texts:[
            'To book a collection slot you must Login and start a new order, from here you can choose a time slot for collection.',
            'Click the 30 minute you would like to collect and you are ready to go! Orders can be placed from 6am - 4pm on the collection day. Please arrive within your 30 minute window to collect your order.'
        ] },
        { title:'What days can I collect?', texts:[
            'Our cash and collect counter service is open 7 days a week.',
            'Monday - Friday: 06:00am and 17:00pm.',
            'Saturday: 08:00 to 17:00',
            'Sunday: 10:00 to 16:00',
            'Collection slots are 30 minute windows.'
        ] },
        { title:'What is the minimum spend for Collection?', texts:[
            'The minimum spend is £50.00 after any discounts that have been applied to your order at the checkout.'
        ] },
        { title:'What if I need to change my collection date or time?', texts:[
            'You have until 1pm on the day of collection to amend your order. Simply log into your account on our website and make the changes to your order. Remember to checkout your order before 1pm.'
        ] },
        { title:'How do I collect my shopping? ', texts:[
            'You will recieve an email confirmation of your order and details of your chosen branch. Please collect your order at your chosen branch.',
            'On arrival, a member of staff will greet you, check your proof of indentity, talk through any specific details for your order (substitutions etc) and ask you to sign for your order.',
            'If your vehicle is parked in the customer collection space, a member of staff will help load your order into your car, if not, they will hand over the items for you to take awa',
            'When collecting your order, please bring one of the following:',
            '- Booking confirmation',
            '- Debit/Credit card used to pay for order',
            '- Photographic ID',
            'Please note, we operate a Think 25 policy on age restricted products, therefore, if a staff member requests ID for restricted products, photographic ID (stated above) will be required.'
        ] },
        { title:'What if I am late for collection? ', texts:[
            'When ordering your products/groceries you will need to select a collection slot and checkout your order. You will then need to arrive during this time slot (30 minute time window) at the chosen branch site to guarantee receiving your order.',
            'Failure to arrive during your chosen collection slot may mean that your order is unavailable to collect. Arriving after the 5pm closing time will result in the total amount being refunded to your payment card within 3-5 working days'
        ] },
    ]
  }
  const howtoshop = {
    values:[
        { title:'How do I register as a new customer? ', texts:[
            '1. To register click the Register link in the top right hand corner',
            '2. Fill in the account registration details',
            '3. Once you have filled in your details, you will be sent an email asking you to confirm your email address. Click the confirmation link.',
            '4. Your account will be sent for approval.',
            '5. Once the account has been approved you will be sent a welcome email. You are now officially registered and ready to start ordering online.',
            '6. Sign in using your email address and password you set up while registering.',
            '7. Once logged in, you are taken to My Account dashboard and are ready to start ordering online.'
        ] },
        { title:'Can I place my order over the phone?', texts:[
            'Yes. Call our Sales Team office on: 020 8527 2666*. (*Calls may be recorded for training purposes.)',
            'Open times are:',
            'Monday - Friday: 09:00am and 17:30pm',
            'Saturday: Closed',
            'Sunday: 10:30 to 17:30'
        ] },
        { title:'How do I see special offers? ', texts:[
            'You can view our special offers, such as Magnificent 7 and Star Saver deals by clicking on to the relevant tab. Also, if a product is included in an offer, this will be indicated with the Buy quantity showing underneath the product.'
        ] },
        { title:'How do I search for a product? ', texts:[
            'To search for a product you can do any of the following:',
            'Search Box: If you are looking for a specific item (for example Euro Chips) there is a search box on the top of the screen, underneath the Star logo. Type the Product or Code in the search box, press enter and a list of items that match your description will then be listed.',
            'By Category: Hover over and click the heading Browse Categories on the home page, or once signed in use the Product Categories tab to see a drop down filter to search for the required item.',
            'Favourites: Whilst you shop, you can create a list of favourites based on what you like to buy most frequently. To add to your favourites, click the Star Icon next to the item and this will add to "Favourites", you will see confirmation immediately that this has been added. If you click on the "Favourites" tab at the top of the page, you can browse through and shop from a list of products you have added to your favourites before. Products that are ordered regularly will be automatically added to your favourites.'
        ] },
        { title:'How do I amend products in my basket?', texts:[
            'Click into My Account section, you will find your order in My Orders. Amend your order and proceed to the checkout section to complete. Any amendments made to the order will be confirmed by email.'
        ] },
        { title:'How do I checkout?', texts:[
            'Once you have added everything you want to buy, just click the Checkout button - minimum spend required before you can do this.'
        ] },
        { title:'Can I save my order and complete it later?', texts:[
            'Any times you have added to your basket will be saved, and be completed later for when you log back into your account. When logging back into your account you will be prompted to select a new delivery or collection slot.'
        ] },
        { title:'What happens if an item is unavailable? ', texts:[
            'Please check back later or call our Sales Team on 020 8527 2666* for details *Calls may be recorded for training purposes'
        ] },
        { title:'What happens if an item I ordered is unavailable? ', texts:[
            'We do our best to ensure that we have a wide range of products available every day but sometimes we run out of stock. If we run out of any products that you have ordered we will try to replace them with the same products but in a different pack size or quantity.',
            'You will only be charged for the products you have ordered and we will never replace unavailable products with products of a lower value or smaller pack size.',
            'If we are unable to supply any products, including an alternate, you will be charged for the unavailable products.'
        ] }
    ]
  }

  const myaccount = {
    values:[
        { title:'How do I change my personal details? ', texts:[
            'Click into the My Account section, enter your profile and make the required changes.'
        ] },
        { title:'Forgotten your password? ', texts:[
            'If you can not remember your password, please follow the Forgotten your password link and we will send you an email for you to reset it. This will prompt you to create a new password.',
            'You will then need to confirm your new password by retyping it.'
        ] },
        { title:'How do I update my password? ', texts:[
            'Click on the Change Password link in the My Account section and update your password.'
        ] },
        { title:'how do I change a billing/delivery address? ', texts:[
            'Click into the Manage Billing Address/Manage Delivery Address of the My Account section and edit details.'
        ] },
        { title:'How do I add a secondary delivery address? ', texts:[
            'Click into Manage Delivery Address in the My Account section and edit details.'
        ] },
        { title:'How do I update my email address? ', texts:[
            'Click on the Update email button in the My Account section and update your email address.'
        ] }
    ]
  }
  const myorders = {
    values:[
        { title:'How do I order?', texts:[
            'To order online you need to create an account or login, to create an account go to the home page and click onto Shop Online/Login, you can now register online or login and start ordering.'
        ] },
        { title:'How do I cancel an order?', texts:[
            'You can cancel your order right up to 5:30pm the day before delivery',
            'Got to My Account, Order History and find the order you would like to cancel. Just click Cancel underneath the Amend Order button and you will be asked to confirm cancellation.',
            'Once you have confirmed to cancel the order you will notice the status will change in your Order History to cancelled.'
        ] },
        { title:'How do I see my order history? ', texts:[
            'Your order history is held in the My Account section.'
        ] },
        { title:'How is my order confirmed? ', texts:[
            'A confirmation email will be sent to your email address.This will also contain your order number.'
        ] },
        { title:'Can I order to a different address? ', texts:[
            'Yes you can, when you have signed into your account, under My Address click and you will see a link Manage Delivery Addresses, click this and add an additional address or edit your default to a new address'
        ] },
        { title:'When will my order be delivered? ', texts:[
            'Your order will be delivered within the 4 hour window chosen by you when your order was placed.'
        ] },
        { title:'When will my order be delivered? ', texts:[
            'Yes, our driver will bring you a receipt. Your items will be listed in categories so they are easy to follow and will also include any discounts.',
            'You will be asked to sign for your items as proof of delivery.'
        ] },
        { title:'Can I return a product?', texts:[
            'We don nott accept returns on items that have been bought in error or if you have changed your mind. If however, there is a quality issue with anything purchased, we will happily refund your item for you by contacting our Sales Team on 020 8527 2666*, or by you returning the item to the store, along with your receipt.',
            '*Calls may be recorded for training purposes.'
        ] }
    ]
  }
  const priceandpayments = {
    values:[
        { title:'What credit/debit cards do you accept?', texts:[
            'For online payments we accept Visa, Mastercard and *PayPal as methods of payment.',
            '*Paypal option coming soon.',
            'We accept all other major credit/debit cards in store only*',
            '*Minimum £50 Card Purchase.'
        ] },
        { title:'How will I get a receipt? ', texts:[
            'Your driver will hand you your receipt. The driver will also ask you to sign their copy as proof of purchase.'
        ] },
        { title:'How do I amend my billing address?', texts:[
            'Click into "My Account" section and go to "My Addresses" and then manage bulling, to make changes click edit and then save.'
        ] },
        { title:'How do I add new/change a payment method? ', texts:[
            'You can only add payment details on the payment page when you are ready to pay for your order. You can remove payment details by going to My Account and go to My Cards then select Manage Payment Details'
        ] },
        { title:'When is payment taken?', texts:[
            'Payment will be taken on the day of delivery/collection.'
        ] },
        { title:'How long would a refund take? ', texts:[
            'Refunds for transactions made using a debit or credit card may take 5-7days (not including weekends and bank holidays).',
            'Direct contact with your card provider may also help you track the status of your refund.',
            'PayPal refunds made through your bank account will go directly back into your account within 24 hours. Any PayPal transactions made using a debit or credit card will be subject to the conditions above, and could take between 5-7 working days'
        ] },
        { title:'How often do you review prices?', texts:[
            'We regularly review our prices to make sure we offer value for money whilst remaining competitive. Although we try to keep our pricing as fair as possible, sometimes prices can go up as well as down.',
            'There are lots of factors which can affect pricing, from costs of raw materials, to the production and packaging and even transportation. Whilst we try to absorb these changes for as long as possible, sometimes we eventually have to alter the price. With over 1,500 products in our range, reviews and changes do happen regularly.'
        ] }
    ]
  }
  const products = {
    values:[
        { title:'How many products do you sell?', texts:[
            'We have a large product portfolio consisting of over 1,500 products, which will give you a choice of quality and value for money on products.'
        ] },
        { title:'How do I find a product in stock?', texts:[
            'To find a product in stock, you can use the search feature, browse through our Product Categories or call our Sales Team on 020 8527 2666.'
        ] },
        { title:'What if a product is out of stock? ', texts:[
            'If a product is out of stock, please check back tomorrow or call our Sales Team on 020 8527 2666 to find out when stock will be available again.'
        ] },
        { title:'Why do you discontinue Products?', texts:[
            'We hate to disappoint our customers but sometimes we have no choice when, for example, our supplied has stopped producing the product.',
            'On other occasions we will decide based on historical, current and projected sales. We will always make a decision that we feel will benefit the majority of our customers, giving the widest choice possible in the space available.'
        ] },
    ]
  }
  const refunds = {
    values:[
        { title:'What is your refund policy? ', texts:[
            'We will only credit the customer if the wrong product was delivered and returned on the same day with the same delivery driver in original condition. Any other returns, our drivers are not authorised to accept goods to return without the appropriate documentation.'
        ] },
        { title:'What products can not be returned to store?', texts:[
            'We dont accept returns on Frozen items that have been bought in error or if you have changed your mind.',
            'If however, there is a quality issue with anything purchased, we will happily refund your item for you by contacting our Sales Team on 020 8527 2666*, or by you returning the item to the store, along with your receipt.',
            '*Calls may be recorded for training purposes.'
        ] },
        { title:'Can I return a faulty or damaged product?', texts:[
            'If your product is faulty or damaged, we will gladly refund it. However, if the product affected is a perishable product, then you must call us before the expiry of the "use by", "best before" or "BBE" date stamped on the product to receive a refund.',
            'While we dont offer exchanges, you are more than welcome to re-order any refunded item on your next shop.'
        ] },
        { title:'How can I get a refund for missing items? ', texts:[
            'When your delivery arrives, if you notice an item is missing, please let your driver know and your bill will be instantly recalculated. If you realise any items are missing from your order after your driver has left, please contact our Sales Team on 020 8527 2666*',
            '*Calls may be recorded for training purposes.'
        ] }
    ]
  }
  const technical = {
    values:[
        { title:'I am having problems registering?', texts:[
            'If you receive an error stating "the postcode is invalid" please ensure the postcode you have entered is a recognised UK postcode.',
            'A common mistake is using the letter "o" instead of the number "0".'
        ] },
        { title:'I am having problems connecting to the site?', texts:[
            'Try connecting to another website. If you can not then call your Internet Service Provider for assistance.',
            'If you are able to connect to other websites but not https://www.star-catering.co.uk/, please check that you are typing in the correct address: https://www.star-catering.co.uk/',
            'Please also ensure that you are not clicking on an out-of-date bookmark or favourite.'
        ] },
        { title:'I am having problems adding or subtracting items from my basket? ', texts:[
            'If you are having problems adding or removing items from your basket, we would advise you to delete your temporary internet files/ Cache.',
            'For more information on how to do this, please consult your web browser help.',
            'It may also be advisable to update to the latest version of your browser.'
        ] },
        { title:'I am having problems signing into my account?  ', texts:[
            'If you have changed your email address recently then you may need to update your account details. To do this simply sign in your old email address and password, then click on the "My Account" at the top of the page, and make the changes.',
            'If you have forgotten your password please click on "forgotten password" and we will send you an email where you can reset your password.'
        ] },
        { title:'When I try to sign in I get returned to the same page?', texts:[
            'When this happens you will need to verify that your browser is accepting cookies.',
            'To check this in your browser on Windows click on the tools menu from your web browser, select internet options, and then go to the button called advanced. From the details you are shown please ensure that all the SSL options are ticked.'
        ] },
        { title:'I am having problems accessing the checkout?', texts:[
            'When this happens you will need to check that you have the correct SSL (security) settings.',
            'To do this in your browser on Windows click on the tools menu from your web browser, select Internet options, and then go to the button called advanced. From the details you are shown please ensure that all the SSL options are ticked.'
        ] },
        { title:'Errors appear when I try to confirm my order at the checkout? ', texts:[
            'if you cant checkout, you may see one of the following error messages. Take the following steps to resolve the issue you are experiencing:',
            '- Sorry the card number you have entered is invalid.',
            'Please make sure you are using a valid card type and entering the card number correctly and without spaces. Please also be aware that Star Catering Supplies Ltd accepts Mastercard, Visa Credit, Visa Debit, Electron (UK registered cards only) and a valid PayPal account. We do not accept American Express cards',
            'The security code on your card is invalid',
            'we ask for the security number from your payment card to prevent the fraudulent use of your account. On most cards, the security code is the last 3 digits printed on the signature strip',
            '- Expiry date on your card is invalid',
            'If your card expires at the end of the current month it may not verify on the site as it will expire before we take payments and we will not be able to verify your new card as it will not be activated until your current card expires.',
            '- The issue number you supplied is invalid',
            'We only need issue numbers for Maestro cards, if you card is Visa or Mastercard please do not enter an issue number, just leave the box blank.',
            '- Your card has not been authorised.',
            'There has been a problem authorising your card. We would advise you to contact your issuing bank to resolve this. If your card issuer cannot help then please refer to your web browser (e.g. Firefox, Google, Chrome, Safari) support. Delete your browsing history and lear any temporary internet files and cookies, in case these are causing the issue.'
        ] }
    ]
  }

  const [contentValue,setContentValues] =  useState(delivery)
  const [label,setLabel] =  useState('Delivery')
  return (
    <Container height={height} width={width}>
      <Helmet>
        <title>FAQs</title>
        <meta
          name="description"
          content="FAQs"
        />
        <meta name="robots" content="index, nofollow" />
      </Helmet>
      <TwoColumn>
        <LeftDiv>
        <h3>Help Categories</h3>
            <List>
              <li><Element selected={label==='Delivery'} onClick={()=>{setContentValues(delivery);setLabel('Delivery');}}>Delivery</Element></li>
              <li><Element selected={label==='Collection'}  onClick={()=>{setContentValues(collection);setLabel('Collection');}}>Collection</Element></li>
              <li><Element selected={label==='How to Shop'}  onClick={()=>{setContentValues(howtoshop);setLabel('How to Shop');}}>How to Shop</Element></li>
              <li><Element selected={label==='My Account'}  onClick={()=>{setContentValues(myaccount);setLabel('My Account');}}>My Account</Element></li>
              <li><Element selected={label==='My Orders'}  onClick={()=>{setContentValues(myorders);setLabel('My Orders');}}>My Orders</Element></li>
              <li><Element selected={label==='Prices & Payment'}  onClick={()=>{setContentValues(priceandpayments);setLabel('Prices & Payment');}}>Prices & Payment</Element></li>
              <li><Element selected={label==='Products'}  onClick={()=>{setContentValues(products);setLabel('Products');}}>Products</Element></li>
              <li><Element selected={label==='Refunds & Returns'}  onClick={()=>{setContentValues(refunds);setLabel('Refunds & Returns');}}>Refunds & Returns</Element></li>
              <li><Element selected={label==='Technical'}  onClick={()=>{setContentValues(technical);setLabel('Technical');}}>Technical</Element></li>
            </List>
        </LeftDiv>
        <RightDiv>
            <h3>{label}</h3>
            {
                contentValue.values.map((item, index) => {
                return <FaqsAccordionMenu key={index} title={item.title}  values={item.texts}/>;
                })
            }
        </RightDiv>
      </TwoColumn>
    </Container>
  );
}

export default FaqsContent;
