/* eslint-disable jsx-a11y/iframe-has-title */

import styled from "styled-components";
import useForm from "../../utilities/hooks/useForm";
import {validateInfo} from '../../utilities/helpers';
import { colors } from "../../res/values/values";
import { Print, Call, Mail} from "@material-ui/icons";

//#region Styles

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const DivContent = styled.div`
  width: 90%;
  margin: 1rem;
  text-align: center;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
const ContactUsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //padding: 4rem;
 
  font-family: "Raleway", sans-serif;
  
  & p {
    color: #777;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    text-decoration: none;
    display: block;
  }
  & a {
    color: #777;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    text-decoration: underline;
    display: block;
  }
  & h2 {
    margin-bottom: 2rem;
    color: #777;
    font-size: 42px;
    font-weight: 700;
    text-transform: capitalize;
  }
  & h4 {
    color: #777;
    font-size: 15px;
    line-height: 1.58em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;
  border: 1px;
  border-color: lightgray;
  border-style: solid;
  &:focus {
    outline: none !important;
    border-color: ${colors.primaryColor};
  }
`;
const MultiInput = styled.textarea`
  margin: 10px 0;
  padding: 10px;
  border: 1px;
  height: 15vw;
  border-color: lightgray;
  border-style: solid;
  text-align: start;
  &:focus {
    outline: none !important;
    border-color: ${colors.primaryColor};
  }
`;
const Button = styled.input`
  color: ${colors.whiteColor};
  font-size: 15px;
  font-weight: 600;
  border: none;
  padding: 15px 20px;
  background-color: ${colors.darkcolor};
  opacity: 80%;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.primaryColor};
  }
`;
const DividerLine = styled.div`
  border-bottom: 0.1rem solid #D5393F;
  width: 90%;
  margin: 20px;
  margin-left: 5px;
`

//#endregion
function ContactContent(props) {
  const { handleChange, handleSubmit, values, errors } = useForm(
    props.sendMail,
    validateInfo
  );
  return (
    <Container>
      <iframe 
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4951.180030410553!2d-0.027427!3d51.649033!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761fb71ce080c9%3A0x37b09ed7eaf233dc!2sCentenary%20Estate%2C%201%20Jeffreys%20Rd%2C%20Enfield%20EN3%207UD!5e0!3m2!1sen!2suk!4v1689239264271!5m2!1sen!2suk"
      width="100%"
       height="450" 
       style={{border:0,marginTop:5}} 
       allowfullscreen="" 
       loading="lazy" ></iframe>
      <ContactUsDiv>
        <DivContent>
          <h2>London Depot</h2>
          <h4>
          Star Catering Supplies Ltd <br />
          Unit 1-8, Centenary Estate
          Jeffreys Rd
          Enfield
          London
          EN3 7UD
          </h4>
          <p>For media enquiries, call</p>
          <p> <Call/>  +44 (0) 20 8527 2666</p>
          <p><Print/>  +44 (0) 20 8503 2029</p>
        </DivContent>
        
       
      </ContactUsDiv>
      <h2> Openning Times</h2>
      <ContactUsDiv>
        
        <DivContent>
         <h4>
          Cash &amp;Collect Counter Service : 
          </h4>
          <p>Mon - Fri : 06.00 to 20.00</p>
          <p>Sat : 08.00 to 17.00</p>
          <p>Sun : 08.00 to 16.00</p>
        </DivContent>
        <DivContent>
        <h4>
          Telesales Office
          </h4>
          <p>Mon - Thu : 09.00 to 18.00</p>
          <p>Fri : 09.00 to 17.00</p>
          <p>Closed</p>
          <p>Sun : 10.15 to 18.00</p>
        </DivContent>
        <DivContent>
         <h4>
          Answerphone Orders
          </h4>
          <p>Mon - Sun : 18.00 to 09.00*</p>
        </DivContent>
      </ContactUsDiv>
      <DividerLine/>
      <ContactUsDiv>
        
        {/* MANCHESTER */}
        <DivContent>
         <h3>
          Customer Services
          </h3>
          <p><Call/> 07502 236 506</p>
          <p><Mail/> customerservices@starcateringuk.com</p>
        </DivContent>
        
      </ContactUsDiv>
      <DividerLine/>
      <DivContent>
          <Form onSubmit={handleSubmit}>
            <Input
              name="email"
              onChange={handleChange}
              type="text"
              placeholder="email"
              value={values.email}
            />
            {errors.email && <p style={{color:'red'}}>{errors.email}</p>}
            <Input
              name="name"
              onChange={handleChange}
              type="text"
              placeholder="name"
              value={values.name}
            />
            {errors.name && <p style={{color:'red'}}>{errors.name}</p>}
            <MultiInput
              name="message"
              onChange={handleChange}
              type="text"
              placeholder="your message (optional)"
              value={values.message}
            />
            {errors.message && <p style={{color:'red'}}>{errors.message}</p>}
            <Button type="submit" value="SUBMIT"></Button>
          </Form>
      </DivContent>
    </Container>
  );
}

export default ContactContent;


/*
<DivContent>
         <h3>
          Distribution Centers
          </h3>
          <strong>Manchester</strong>
          <p>Unit 7 Junction 19 Industrial Park Green Lane / Heywood OL10 1NB</p>
          <a href="mailto:customerservices@starcateringuk.com">customerservices@starcateringuk.com</a>
          <p><Call/>07502 236 506</p>
        </DivContent>
*/